import React, { useState } from 'react';

import { Upload } from 'antd';
import { CustomConfirmationModal } from 'shared/components';
import { CustomButton, CloseButton } from 'shared/components';

import { StyledUploadFileModal } from './styled/UploadFileModal.styled';

export const UploadFileModal = ({
    isModalOpen,
    setIsModalOpen,
    title,
    formikData,
    onUploadClick = () => {}
}) => {
    const [fileList, setFileList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [deleteConfirmationModal, setDeleteConfirmationModal] =
        useState(false);
    const handleClose = () => {
        setIsModalOpen(false);
    };

    const checkForDuplicateFiles = file => {
        return (
            fileList.some(f => f.name === file.name) ||
            formikData.files.some(f => f.name === file.name && !f.isDeleted)
        );
    };

    const uploadProps = {
        onRemove: file => {
            var newFileList = fileList.filter(f => f.uid !== file.uid);
            setFileList(newFileList);
            return newFileList;
        },
        beforeUpload: file => {
            if (checkForDuplicateFiles(file)) {
                setSelectedFile(file);
                setDeleteConfirmationModal(true);
                return Upload.LIST_IGNORE;
            } else {
                setFileList([...fileList, file]);
            }
            return false;
        }
    };

    const removeDuplicateFile = file => {
        let newFileList = fileList.filter(f => f.name !== file.name);
        setFileList(newFileList);

        return newFileList;
    };

    const handleDelete = () => {
        //delete file from fileList
        var newFileList = removeDuplicateFile(selectedFile);

        //add new file to fileList and close modal
        setFileList([...newFileList, selectedFile]);
        setSelectedFile(null);
        setDeleteConfirmationModal(false);
    };

    const closeModalCallback = () => {
        setDeleteConfirmationModal(false);
    };

    return (
        <StyledUploadFileModal
            visible={isModalOpen}
            setVisible={setIsModalOpen}
            title={[
                <div className="custom-modal-header" key="modal-title">
                    {title}
                    <CloseButton handleClose={handleClose} />
                </div>
            ]}
            footer={[
                <div className="custom-modal-footer" key="modal-footer">
                    <div className="button-section">
                        <CustomButton
                            text="Cancel"
                            type="normal footer-button"
                            onClick={handleClose}
                        />
                    </div>
                    <div className="button-section">
                        <CustomButton
                            text="Upload"
                            type="filled footer-button"
                            onClick={() => {
                                onUploadClick(fileList, formikData);
                            }}
                            disabled={fileList.length === 0}
                        />
                    </div>
                </div>
            ]}
        >
            <div className="modal-upload-section">
                <Upload
                    {...uploadProps}
                    multiple={true}
                    fileList={fileList}
                    defaultFileList={fileList}
                >
                    <CustomButton type="normal" text={'Browse Files'} />
                </Upload>
            </div>
            {deleteConfirmationModal && (
                <CustomConfirmationModal
                    title={'Confirmation'}
                    visible={deleteConfirmationModal}
                    setVisible={setDeleteConfirmationModal}
                    contentText={`Are you sure you want to replace the existing file(s) with the same name?`}
                    secondaryTitle={''}
                    handleConfirm={handleDelete}
                    closeCallback={closeModalCallback}
                />
            )}
        </StyledUploadFileModal>
    );
};
