import { isEqual, isEmpty } from 'lodash';
import { decimalRegex } from 'shared/constants/regexConstants';

const ERROR_MESSAGES_RICH_TEXT = {
    benefitTypeDescription:
        'Benefit Type Description should not exceed 1000 characters.',
    benefitTypeOverview:
        'Benefit Type Overview should not exceed 1000 characters.',
    benefitTypeHowToUse:
        'Benefit Type How to Use should not exceed 1000 characters.',
    benefitTypeContacts:
        'Benefit Type Contacts should not exceed 1000 characters.'
};

export const MAX_LENGTH_RICH_TEXT = 1000;
export const MAX_LENGTH_SHORT_DESCRIPTION = 100;
export const MIN_LENGTH_SHORT_DESCRIPTION = 3;
export const MAX_LENGTH_IMAGE_ALT_TEXT = 120;
export const MAX_LENGTH_CONTENTFUL_ID = 64;
export const BENEFIT_TYPE_CONTENTFULID_REGEX_PATTERN = /^[a-zA-Z0-9-_.]{1,64}$/;

export const disableBenefitType = (values, error) => {
    if (
        (values.benefitTypeName || values.benefitTypeShortDescription) &&
        !(
            error?.benefitTypeName ||
            error?.benefitTypeShortDescription ||
            error?.benefitTypeImageAltText ||
            error?.benefitTypeImage ||
            error?.benefitContentfulId
        )
    ) {
        return false;
    } else {
        return true;
    }
};

export const validateBenefitTypeForm = ({ benefitTypeName }) => {
    let errors = { benefitTypeName: undefined };

    if (benefitTypeName?.trim() === '') {
        errors = {
            ...errors,
            benefitTypeName: 'Benefit Type Name is required.'
        };
    }
    return errors;
};

export const validateRichTextLength = (fieldName, value) => {
    return value?.trim().length > MAX_LENGTH_RICH_TEXT
        ? {
              [fieldName]: ERROR_MESSAGES_RICH_TEXT[fieldName]
          }
        : { [fieldName]: undefined };
};

export const validateBenefitTypeOnSubmit = ({
    benefitTypeName,
    benefitTypeShortDescription,
    benefitTypeImageAltText,
    benefitTypeImage,
    benefitContentfulId
}) => {
    let errors = {};

    if (benefitTypeName?.trim() === '') {
        errors = {
            ...errors,
            benefitTypeName: 'Benefit Type Name is required.'
        };
    }

    if (!benefitTypeImageAltText?.trim()) {
        errors = {
            ...errors,
            benefitTypeImageAltText: 'Benefit type image alt text is required.'
        };
    } else if (benefitTypeImageAltText.length > MAX_LENGTH_IMAGE_ALT_TEXT) {
        errors = {
            ...errors,
            benefitTypeImageAltText:
                'Benefit type image alt text can be up to 120 characters long.'
        };
    }

    if (
        benefitContentfulId &&
        benefitContentfulId.length > MAX_LENGTH_CONTENTFUL_ID
    ) {
        errors = {
            ...errors,
            benefitContentfulId: `Benefit Type Contentful ID can be a maximum of ${MAX_LENGTH_CONTENTFUL_ID} symbols.`
        };
    } else if (
        benefitContentfulId &&
        !BENEFIT_TYPE_CONTENTFULID_REGEX_PATTERN.test(benefitContentfulId)
    ) {
        errors = {
            ...errors,
            benefitContentfulId: `Benefit Type Contentful ID format is not valid.`
        };
    }

    if (!benefitTypeImage) {
        errors = {
            ...errors,
            benefitTypeImage: 'Benefit Type Image is required.'
        };
    }

    if (
        benefitTypeShortDescription?.trim() === '' ||
        benefitTypeShortDescription === null
    ) {
        errors = {
            ...errors,
            benefitTypeShortDescription:
                'Benefit Type short description is required.'
        };
    } else if (
        benefitTypeShortDescription?.trim().length >
        MAX_LENGTH_SHORT_DESCRIPTION
    ) {
        errors = {
            ...errors,
            benefitTypeShortDescription:
                'Benefit Type Short Description should not exceed 100 characters.'
        };
    } else if (
        benefitTypeShortDescription?.trim().length <
        MIN_LENGTH_SHORT_DESCRIPTION
    ) {
        errors = {
            ...errors,
            benefitTypeShortDescription:
                'Benefit Type Short Description should be more than 3 characters.'
        };
    }

    return errors;
};

export const validateShort = ({ benefitTypeShortDescription }) => {
    let errors = { benefitTypeShortDescription: undefined };

    if (
        benefitTypeShortDescription == null ||
        benefitTypeShortDescription?.trim() === ''
    ) {
        errors = {
            ...errors,
            benefitTypeShortDescription:
                'Benefit Type short description is required.'
        };
    }

    if (
        benefitTypeShortDescription?.trim().length >
        MAX_LENGTH_SHORT_DESCRIPTION
    ) {
        errors = {
            ...errors,
            benefitTypeShortDescription:
                'Benefit Type Short Description should not exceed 100 characters.'
        };
    }

    if (
        benefitTypeShortDescription?.trim().length <
        MIN_LENGTH_SHORT_DESCRIPTION
    ) {
        errors = {
            ...errors,
            benefitTypeShortDescription:
                'Benefit Type Short Description should be more than 3 characters.'
        };
    }

    return errors;
};

export const disableBenefitPrice = (values, error) => {
    // if it is deleted, ignore errors
    if (!values.active || isEmpty(error)) {
        return false;
    } else {
        return true;
    }
};

export const validateBenefitPriceForm = ({ benefitPrice, active }) => {
    let errors = {};

    if (!active) return errors;

    let temptBenefitPrice = benefitPrice.toString();
    if (temptBenefitPrice) {
        let benefitPriceError;
        if (
            isNaN(temptBenefitPrice) ||
            !decimalRegex.test(temptBenefitPrice) ||
            temptBenefitPrice?.trim() === ''
        ) {
            benefitPriceError = `Benefit price should be in correct format.`;
            errors = { ...errors, benefitPrice: benefitPriceError };
        }
    }

    return errors;
};

export const validateBenefitPriceOnSubmit = (
    { benefitPrice, startDate, endDate, active },
    isLast
) => {
    let errors = {};

    if (!active) {
        return {};
    }

    let temptBenefitPrice = benefitPrice.toString();
    if (temptBenefitPrice?.trim() !== '') {
        if (isNaN(temptBenefitPrice) || !decimalRegex.test(temptBenefitPrice)) {
            errors = {
                ...errors,
                benefitPrice: `Benefit price should be in correct format.`
            };
        }
    } else {
        errors = { ...errors, benefitPrice: `Benefit price is required.` };
    }

    if (!startDate) {
        errors = {
            ...errors,
            startDate: `Benefit price start date is required.`
        };
    }

    if (!isLast && !endDate) {
        errors = {
            ...errors,
            endDate: `Benefit price end date is required.`
        };
    }

    return errors;
};

export const benefitPricesValid = (benefitPrices, errors) => {
    const resultErrors = [];
    const resultAllowSubmit = [];

    benefitPrices.forEach((benefitPrice, index) => {
        const currentValue = benefitPrice;
        const currentError = errors[index];
        const activeBenefits = benefitPrices.filter(
            benefitPrice => benefitPrice.active
        );
        const isLast = isEqual(
            activeBenefits[activeBenefits.length - 1],
            benefitPrice
        );

        const error = validateBenefitPriceOnSubmit(currentValue, isLast);
        const result = disableBenefitPrice(currentValue, {
            ...error,
            ...currentError
        });

        resultErrors.push(error);
        resultAllowSubmit.push(result);
    });

    return {
        submitErrors: resultErrors,
        allowSubmit: resultAllowSubmit.every(x => x === false)
    };
};

export const prepareBenefitPrices = benefitPrices => {
    const transformedBenefitPrices = [];
    let previousActive = null;

    benefitPrices.forEach((benefitPrice, index) => {
        transformedBenefitPrices.push({
            benefitPrice,
            previousActive,
            nextActive: benefitPrices
                .slice(index + 1)
                .find(price => price.active)
        });

        if (benefitPrice.active) {
            previousActive = benefitPrice;
        }
    });

    return transformedBenefitPrices;
};

export const validateBenefitTypeImageAltText = (
    setErrors,
    benefitTypeImageAltText
) => {
    let nextErrors = {};

    setErrors(prev => {
        delete prev.benefitTypeImageAltText;
        nextErrors = { ...prev };
        return prev;
    });

    if (benefitTypeImageAltText?.trim() === '') {
        nextErrors = {
            ...nextErrors,
            benefitTypeImageAltText: 'Benefit type image alt text is required.'
        };
    } else if (benefitTypeImageAltText.length > MAX_LENGTH_IMAGE_ALT_TEXT) {
        nextErrors = {
            ...nextErrors,
            benefitTypeImageAltText:
                'Benefit type image alt text can be up to 120 characters long.'
        };
    }

    setErrors(nextErrors);
};

export const validateBenefitContentfulId = (setErrors, benefitContentfulId) => {
    let nextErrors = {};

    setErrors(prev => {
        delete prev.benefitContentfulId;
        nextErrors = { ...prev };
        return prev;
    });

    if (
        benefitContentfulId &&
        benefitContentfulId.length > MAX_LENGTH_CONTENTFUL_ID
    ) {
        nextErrors = {
            ...nextErrors,
            benefitContentfulId: `Benefit Type Contentful ID can be a maximum of ${MAX_LENGTH_CONTENTFUL_ID} symbols.`
        };
    } else if (
        benefitContentfulId &&
        !BENEFIT_TYPE_CONTENTFULID_REGEX_PATTERN.test(benefitContentfulId)
    ) {
        nextErrors = {
            ...nextErrors,
            benefitContentfulId: `Benefit Type Contentful ID format is not valid.`
        };
    }

    setErrors(nextErrors);
};
