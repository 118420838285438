import { errorNotification } from 'shared/components/Notifications';

const MAX_LENGTH_IMAGE_ALT_TEXT = 120;

export const validateOfferForm = (values, errors) => {
    const {
        image,
        imageAltText,
        name,
        shortDescription,
        offerId,
        howToUse_plainText,
        howMuch_plainText,
        locations
    } = values;
    let nameError;

    // name validation
    if (image !== undefined && (image === null || image === '')) {
        errors = { ...errors, image: 'Offer image is required.' };
    } else if (image !== undefined) {
        errors = { ...errors, image: '' };
    }

    if (
        imageAltText !== undefined &&
        (imageAltText === null || imageAltText === '')
    ) {
        errors = {
            ...errors,
            imageAltText: 'Offer image Alt text is required.'
        };
    } else if (
        imageAltText !== undefined &&
        imageAltText.length > MAX_LENGTH_IMAGE_ALT_TEXT
    ) {
        errors = {
            ...errors,
            imageAltText: `Offer image Alt text has max length of ${MAX_LENGTH_IMAGE_ALT_TEXT} characters.`
        };
    } else if (imageAltText !== undefined) {
        errors = { ...errors, imageAltText: '' };
    }

    // name validation
    if (name !== undefined && name?.trim() === '') {
        nameError = 'Offer name is required.';
    } else if (name !== undefined && name.length > 50) {
        nameError = 'Offer name has max length of 50 characters.';
    } else if (name !== undefined) {
        nameError = '';
    }

    errors = { ...errors, name: nameError };

    // short description validation
    if (shortDescription !== undefined && shortDescription?.trim() === '') {
        errors = {
            ...errors,
            shortDescription: 'Short description is required.'
        };
    } else if (
        shortDescription !== undefined &&
        (shortDescription.length < 3 || shortDescription.length > 1000)
    ) {
        errors = {
            ...errors,
            shortDescription:
                'Short description should not exceed 1000 characters, 3 characters min length.'
        };
    } else if (shortDescription !== undefined) {
        errors = { ...errors, shortDescription: '' };
    }

    //howMuch
    if (
        (offerId === 0 && howMuch_plainText === undefined) ||
        (howMuch_plainText !== undefined && howMuch_plainText?.trim() === '')
    ) {
        errors = {
            ...errors,
            howMuch: 'How much is required.'
        };
    }
    //howToUse
    if (
        (offerId === 0 && howToUse_plainText === undefined) ||
        (howToUse_plainText !== undefined && howToUse_plainText?.trim() === '')
    ) {
        errors = {
            ...errors,
            howToUse: 'How to use is required.'
        };
    }

    //locations
    if (locations !== undefined && locations.length === 0) {
        errors = {
            ...errors,
            locations: 'Location is required.'
        };
    } else if (locations !== undefined && locations.length > 0) {
        errors = {
            ...errors,
            locations: ''
        };
    }

    return errors;
};

export const setClassName = val => {
    if (val) {
        return 'error-input';
    }
};

export const canSubmitOfferForm = error => {
    for (const key in error) {
        if (error[key] !== '') {
            errorNotification(error[key]);
            return false;
        }
    }

    return true;
};

export const getSelectedLocationOnEdit = offer => {
    return offer.locations.map(x => {
        return { value: x.id, label: x.description };
    });
};
