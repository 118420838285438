import React, { useEffect, useState } from 'react';

import { handleSubmit } from 'admin/utils/shared/discountModalHelper';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchDiscounts,
    fetchSingleDiscount,
    resetDiscountState,
    resetFilters,
    changeOrder,
    createDiscount,
    updateDiscount,
    changeActiveState,
    resetNotificationState,
    updateSearchPhrase
} from 'redux/slices/discount';
import { CustomButton } from 'shared/components/Button';
import { successNotification } from 'shared/components/Notifications';

import { StyledDiscountsTab } from './styled/DiscountsTab.styled';
import { DiscountsTable } from './Table/DiscountsTable';
import SubsetTabSearch from '../../../people/components/Dashboard/PeopleSubsetTab/SubsetTabSearch';
import Footer from '../../../shared/components/layout/Footer';
import useInfiniteScroll from '../../../shared/hooks/useInfiniteScroll';
import { DiscountModal } from '../shared/DiscountModal/DiscountModal';

export const DiscountsTab = ({ handleCreateColumns, permissions }) => {
    const dispatch = useDispatch();
    const {
        discounts,
        currentTotal,
        discountsTotalCount,
        isLoading,
        isAddedSuccessfully,
        isEditedSuccessfully,
        isDeletedSuccessfully,
        loadActive,
        searchPhrase,
        sortBy,
        sortAsc
    } = useSelector(state => state.discount);

    const [sortState, setSortState] = useState([
        { id: 'updatedOn', desc: true }
    ]);
    const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
    const [editModalDiscountId, setEditModalDiscountId] = useState(undefined);
    const [fetchDiscountForEdit, setFetchDiscountForEdit] = useState(false);

    useEffect(() => {
        if (isEditedSuccessfully) {
            successNotification('Successfully updated the Discount.');
        } else if (isAddedSuccessfully) {
            successNotification('Successfully added a Discount.');
        } else if (isDeletedSuccessfully) {
            successNotification('Successfully deleted the Discount.');
        }

        dispatch(resetNotificationState());
    }, [isAddedSuccessfully, isEditedSuccessfully, isDeletedSuccessfully]);

    useEffect(() => {
        if (editModalDiscountId && fetchDiscountForEdit) {
            dispatch(fetchSingleDiscount(editModalDiscountId));
            setIsDiscountModalOpen(true);
            setFetchDiscountForEdit(false);
        }
    }, [fetchDiscountForEdit]);

    useEffect(() => {
        dispatch(fetchDiscounts(false));
    }, [searchPhrase, sortBy, sortAsc, loadActive]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, []);

    const handleSortUpdate = value => {
        setSortState(value);
        dispatch(changeOrder(value));
        dispatch(resetDiscountState());
    };

    // infinite scroll handling
    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            dispatch(fetchDiscounts(true));
        },
        isLoading,
        hasMore: Boolean(discounts.length < discountsTotalCount)
    });

    const handleValueUpdate = (_, val) => {
        dispatch(updateSearchPhrase(val));
    };

    const getClass = active => {
        return `inactive-discounts-button${active ? ' active' : ''} btn-normal`;
    };

    const handleDiscountModalOpen = (isOpen, discountId) => {
        if (isOpen && discountId) {
            setEditModalDiscountId(discountId);
            setFetchDiscountForEdit(true);
        } else {
            setIsDiscountModalOpen(isOpen);
            setEditModalDiscountId(undefined);
        }
    };

    return (
        <>
            <StyledDiscountsTab>
                <div className="all-discounts-container">
                    <div className="discounts-extra-header">
                        <SubsetTabSearch
                            search={{
                                handleValueUpdate
                            }}
                            allowLettersOnly={false}
                        />
                        <div className="active-button-container">
                            <button
                                data-testid="active-show-button"
                                className={getClass(loadActive)}
                                onClick={() =>
                                    dispatch(changeActiveState(true))
                                }
                            >
                                Active
                            </button>
                            <button
                                data-testid="inactive-show-button"
                                className={getClass(!loadActive)}
                                onClick={() =>
                                    dispatch(changeActiveState(false))
                                }
                            >
                                Inactive
                            </button>
                        </div>
                        <CustomButton
                            type={'normal'}
                            text={'Add New Discount'}
                            onClick={() => handleDiscountModalOpen(true)}
                            disabled={!permissions.canCreateDiscounts}
                        />
                    </div>
                    <DiscountsTable
                        totalCount={currentTotal}
                        defaultSort={sortState}
                        handleCreateTableColumns={handleCreateColumns}
                        pageSize={10}
                        loading={isLoading}
                        noDataText={'No Discounts Found'}
                        hasPagination={false}
                        sorted={sortState}
                        handleSortUpdate={handleSortUpdate}
                        boundaryRef={boundaryRef}
                        permissions={permissions}
                        handleOpenDiscountModal={handleDiscountModalOpen}
                    />
                    {isDiscountModalOpen && (
                        <DiscountModal
                            title={
                                editModalDiscountId
                                    ? 'Edit Discount'
                                    : 'Add Discount'
                            }
                            visible={isDiscountModalOpen}
                            setVisible={handleDiscountModalOpen}
                            handleSubmit={(requestData, filesForDeletion) =>
                                handleSubmit(
                                    editModalDiscountId,
                                    requestData,
                                    filesForDeletion,
                                    dispatch,
                                    createDiscount,
                                    updateDiscount,
                                    setIsDiscountModalOpen
                                )
                            }
                        />
                    )}
                </div>
            </StyledDiscountsTab>
            {!(discounts.length < discountsTotalCount) && (
                <Footer className={'special'} />
            )}
        </>
    );
};
