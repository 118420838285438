import React from 'react';

import { BenefitPriceSection } from './BenefitPriceSection';
import { validateBenefitPriceForm } from '../../../utils/shared/benefitModalHelper';

const BenefitTypeModalAdd = ({
    benefitPrices,
    setFieldValue,
    benefitPriceErrors,
    setBenefitPriceErrors
}) => {
    const validatePrice = currentValue => {
        let errorsIncome = validateBenefitPriceForm(currentValue);
        setBenefitPriceErrors(prev => {
            let temp = [...prev];
            temp[0] = errorsIncome;
            return temp;
        });
    };

    const handleChange = (propName, propValue) => {
        let valueCopy = [...benefitPrices];
        valueCopy[0] = {
            ...valueCopy[0],
            [propName]: propValue
        };

        setFieldValue('benefitPrices', valueCopy);

        validatePrice(valueCopy[0]);
    };

    return (
        <>
            <BenefitPriceSection
                onChange={handleChange}
                values={benefitPrices[0]}
                benefitPriceId={benefitPrices[0].id}
                benefitPriceErrors={benefitPriceErrors[0]}
                shouldDisableFields={false}
                isEndDateRequired={false}
            />
            <div className="bottom-section last"></div>
        </>
    );
};

export { BenefitTypeModalAdd };
