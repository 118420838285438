import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchCoveredByDropDown } from 'redux/slices/dropdown';
import {
    fetchActiveOffers,
    fetchInactiveOffers,
    resetOfferState,
    resetFilters,
    changeOrder,
    setAddEditOfferModalOpen
} from 'redux/slices/offer';
import { CustomButton } from 'shared/components/Button';

import { StyledOffersTab } from './styled/OffersTab.styled';
import { OffersTable } from './Table/OffersTable';
import SubsetTabSearch from '../../../people/components/Dashboard/PeopleSubsetTab/SubsetTabSearch';
import Footer from '../../../shared/components/layout/Footer';
import useInfiniteScroll from '../../../shared/hooks/useInfiniteScroll';
import { OfferModal } from '../shared/OfferModal/OfferModal';

const DEFAULT_SORT = [{ id: 'updatedOn', desc: false }];

const DEFAULT_STATE = {
    sort: DEFAULT_SORT,
    searchPhrase: undefined
};

export const OffersTab = ({ handleCreateColumns, permissions }) => {
    const dispatch = useDispatch();
    const {
        currentOffers,
        currentTotal,
        currentOffersTotalCount,
        isLoading,
        loadActive,
        isAddEditModalOpen,
        isEditMode
    } = useSelector(state => state.offer);

    const [state, setState] = useState(DEFAULT_STATE);

    useEffect(() => {
        if (loadActive) {
            dispatch(fetchActiveOffers(state.searchPhrase));
        } else {
            dispatch(fetchInactiveOffers(state.searchPhrase));
        }
    }, [dispatch, state]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, []);

    useEffect(() => {
        dispatch(fetchCoveredByDropDown());
    }, []);

    const handleUpdate = which => value => {
        setState({ ...state, [which]: value });
        dispatch(changeOrder(value));
        dispatch(resetOfferState());
    };

    // infinite scroll handling
    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            if (loadActive) {
                dispatch(fetchActiveOffers(state.searchPhrase, true));
            } else {
                dispatch(fetchInactiveOffers(state.searchPhrase, true));
            }
        },
        isLoading,
        hasMore: Boolean(currentOffers.length < currentOffersTotalCount)
    });

    const handleInactiveOffers = () => {
        dispatch(fetchInactiveOffers(state.searchPhrase));
    };

    const handleActiveOffers = () => {
        dispatch(fetchActiveOffers(state.searchPhrase));
    };

    const handleOpenAddModal = () => {
        dispatch(setAddEditOfferModalOpen(true, true));
    };

    const handleValueUpdate = (which, val) => {
        setState(previous => {
            return { ...previous, [which]: val };
        });
    };

    const handleSubmitCreate = values => {
        dispatch(setAddEditOfferModalOpen(false));
    };

    const getClass = active => {
        return `inactive-offers-button${active ? '-active' : ''} btn-normal`;
    };

    return (
        <>
            <StyledOffersTab>
                <div className="all-offers-container">
                    <div className="offers-extra-header">
                        <SubsetTabSearch
                            search={{ handleValueUpdate }}
                            allowLettersOnly={false}
                        />
                        <div className="active-button-container">
                            <button
                                data-testid="active-show-button"
                                className={getClass(loadActive)}
                                onClick={handleActiveOffers}
                            >
                                Active
                            </button>
                            <button
                                data-testid="inactive-show-button"
                                className={getClass(!loadActive)}
                                onClick={handleInactiveOffers}
                            >
                                Inactive
                            </button>
                        </div>
                        <CustomButton
                            type={'normal'}
                            text={'Add New Offer'}
                            onClick={handleOpenAddModal}
                            disabled={!permissions.canCreateOffers}
                        />
                    </div>
                    <OffersTable
                        totalCount={currentTotal}
                        handleCreateTableColumns={handleCreateColumns}
                        offerData={currentOffers}
                        pageSize={10}
                        loading={isLoading}
                        noDataText={'No Offers Found'}
                        hasPagination={false}
                        sorted={state.sort}
                        handleSortUpdate={handleUpdate('sort')}
                        boundaryRef={boundaryRef}
                        permissions={permissions}
                    />
                    {isAddEditModalOpen && (
                        <OfferModal
                            title={isEditMode ? 'Edit Offer' : 'Add Offer'}
                            visible={isAddEditModalOpen}
                            setVisible={setAddEditOfferModalOpen}
                            handleSubmit={handleSubmitCreate}
                        />
                    )}
                </div>
            </StyledOffersTab>
            {!Boolean(currentOffers.length < currentOffersTotalCount) && (
                <Footer className={'special'} />
            )}
        </>
    );
};
