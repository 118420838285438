import RichText from 'shared/components/RichText';

import { MAX_LENGTH_RICH_TEXT } from '../../../utils/shared/benefitModalHelper';

const OverviewTab = ({
    handleRichTextChange,
    values,
    errors,
    setFieldValue
}) => {
    return (
        <RichText
            label="Overview"
            placeholder="Benefit Overview ..."
            value={values.benefitTypeOverview.html}
            name={'benefitTypeOverview'}
            hasError={errors?.benefitTypeOverview}
            onChange={(name, values) =>
                handleRichTextChange(name, values, setFieldValue)
            }
            maxLength={MAX_LENGTH_RICH_TEXT}
            id={'benefitTypeOverview'}
        />
    );
};

export default OverviewTab;
