import React from 'react';

import { Spin } from 'antd';

export const Spinner = ({ size = 'large' }) => {
    return (
        <div data-testid="test-spinner">
            <Spin size={size} />
        </div>
    );
};
