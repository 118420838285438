import RichText from 'shared/components/RichText';

import { MAX_LENGTH_RICH_TEXT } from '../../../utils/shared/benefitModalHelper';

const HowToUseTab = ({
    values,
    handleRichTextChange,
    errors,
    setFieldValue
}) => {
    return (
        <RichText
            label="How To Use"
            placeholder="Benefit How To Use ..."
            value={values.benefitTypeHowToUse.html}
            name={'benefitTypeHowToUse'}
            hasError={errors?.benefitTypeHowToUse}
            onChange={(name, values) =>
                handleRichTextChange(name, values, setFieldValue)
            }
            maxLength={MAX_LENGTH_RICH_TEXT}
            id={'benefitTypeHowToUse'}
        />
    );
};

export default HowToUseTab;
