export const MAX_LENGTH_RICH_TEXT = 1000;
export const MIN_LENGTH_RICH_TEXT = 3;

const ERROR_MESSAGES_RICH_TEXT = {
    howMuch:
        'How much should not exceed 1000 characters, 3 characters min length.',
    howToUse:
        'How to Use should not exceed 1000 characters, 3 characters min length.'
};

export const validateRichTextLength = (fieldName, value) => {
    return value?.trim().length < 3 ||
        value?.trim().length > MAX_LENGTH_RICH_TEXT
        ? {
              [fieldName]: ERROR_MESSAGES_RICH_TEXT[fieldName]
          }
        : { [fieldName]: undefined };
};

export const handleOfferLocationOnChange = (name, values, setFieldValue) => {
    const augmentedValues = values.map(value => {
        return {
            description: value.label,
            isNew: value.__isNew__ ?? false,
            id: value.__isNew__ ? null : value.value
        };
    });

    setFieldValue(name, augmentedValues);
};
