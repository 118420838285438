import React, { useEffect } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDateSelector } from 'shared/components/DatePicker';
import { CustomInput } from 'shared/components/Input';
import { setClassName } from 'utils/componentPropHelpers';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import StyledBenefitPriceSection from './styled/BenefitPriceSection.styled';
import { fetchBenefitPriceGroupsDropDown } from '../../../../redux/slices/dropdown';
import { FieldsSection } from '../../../../shared/components/FieldsSection';
import { CustomSelect } from '../../../../shared/components/Select';

const BenefitPriceSection = ({
    onChange,
    values,
    benefitPriceId,
    benefitPriceErrors,
    shouldDisableFields,
    disabledStartDate,
    isEndDateRequired,
    disabledEndDate
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchBenefitPriceGroupsDropDown());
    }, [dispatch]);

    const [benefitPriceGroups] = useSelector(state => [
        state.dropdown.benefitPriceGroups
    ]);

    const benefitPriceGroupOptions = mapDataForDropdown(benefitPriceGroups);

    return (
        <div data-testid="inner-relative-section">
            <Formik values={values}>
                {props => {
                    const { setFieldValue } = props;

                    return (
                        <Form>
                            <FieldsSection>
                                <StyledBenefitPriceSection>
                                    <CustomInput
                                        label={`Price ${
                                            shouldDisableFields ? '' : '*'
                                        }`}
                                        placeholder="Type..."
                                        value={values.benefitPrice}
                                        className={setClassName(
                                            benefitPriceErrors?.benefitPrice
                                        )}
                                        name={'benefitPrice'}
                                        isNormalChange={false}
                                        onChange={onChange}
                                        allowClear={true}
                                        disabled={shouldDisableFields}
                                    />
                                    <CustomSelect
                                        value={values.benefitPriceGroupId}
                                        label="AHI Group"
                                        name="benefitPriceGroupId"
                                        placeholder="Select from the dropdown"
                                        allowClear={true}
                                        className={setClassName(
                                            benefitPriceErrors?.benefitPriceGroupId
                                        )}
                                        options={benefitPriceGroupOptions}
                                        onChange={onChange}
                                    />
                                    <CustomDateSelector
                                        value={values.startDate}
                                        label={`Start Date ${
                                            shouldDisableFields ? '' : '*'
                                        }`}
                                        name="startDate"
                                        id={`startDate${benefitPriceId}`}
                                        className={setClassName(
                                            benefitPriceErrors?.startDate
                                        )}
                                        onChange={onChange}
                                        disabled={shouldDisableFields}
                                        disabledDate={disabledStartDate}
                                    />
                                    <CustomDateSelector
                                        value={values.endDate}
                                        label={`End Date ${
                                            isEndDateRequired ? '*' : ''
                                        }`}
                                        name="endDate"
                                        id={`endDate${benefitPriceId}`}
                                        className={setClassName(
                                            benefitPriceErrors?.endDate
                                        )}
                                        disabledDate={values.startDate}
                                        disabledFutureDate={disabledEndDate}
                                        disabledDateIncluding
                                        onChange={onChange}
                                        disabled={shouldDisableFields}
                                    />
                                </StyledBenefitPriceSection>
                            </FieldsSection>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export { BenefitPriceSection };
