export const PERMISSIONS_MAPPING = {
    //People page
    1: 'canViewPeoplePage',
    2: 'canViewAllTab',
    3: 'canViewCurrentTab',
    4: 'canViewNewTab',
    5: 'canViewLeaversTab',
    6: 'canViewExTab',
    7: 'canViewPeopleDetailsPage',
    8: 'canExportList',

    //Person details page
    9: 'canSeeContactInformationPhoneNumber',
    10: 'canSeeMainInformationTab',
    11: 'canSeePersonalInformationTab',
    12: 'canSeePersonBenefitsTab',
    13: 'canSeeHistoricalData',
    14: 'canDeletePerson',
    15: 'canEditPersonInformation',
    16: 'canAddWorkingPeriod',
    17: 'canEditWorkingPeriod',
    18: 'canDeleteWorkingPeriod',
    42: 'canAddBenefitToEmployee',

    //Reports page
    19: 'canViewReportsPage',
    20: 'canViewPowerBIReportsTab',
    21: 'canViewBenefitsTab',

    //Admin panel page
    22: 'canViewAdminPage',
    23: 'canViewBenefitTypesTab',
    24: 'canViewRolesTab',
    25: 'canViewUsersTab',
    26: 'canCreateNewBenefitType',
    27: 'canEditBenefitType',
    28: 'canCreateRole',
    29: 'canEditRole',
    30: 'canDeleteRole',
    31: 'canCreateUser',
    32: 'canEditUser',
    33: 'canDeleteUser',
    43: 'canViewDiscountsTab',
    44: 'canCreateDiscounts',
    45: 'canEditDiscount',
    46: 'canViewOffersTab',
    47: 'canCreateOffers',
    48: 'canEditOffers',
    58: 'canDeleteOffer',
    59: 'canDeleteDiscount',
    //49: 'canViewNonFlexibleBenefitTypesTab',
    //50: 'canCreateNonFlexibleBenefitType',
    //51: 'canEditNonFlexibleBenefitType',

    //Org chart page
    34: 'canViewOrgChartPage',

    35: 'canCreateNewPerson',

    //Benefits page
    36: 'canViewBenefitsPage',
    37: 'canViewBenefitsAllTab',
    38: 'canViewBenefitsNewTab',
    39: 'canViewBenefitsMaternityTab',
    40: 'canViewBenefitsLeaversTab',
    41: 'canExportPeopleBenefitsList',

    // Requests page
    52: 'canViewRequestsPage',
    53: 'canViewRequestsPagePendingTab',
    54: 'canViewRequestsPageApprovedTab',
    55: 'canViewRequestsPageDeliveredTab',
    56: 'canViewRequestsPageRejectedTab',
    57: 'canViewRequestsPageCancelledTab',

    60: 'canChangeStatusFromPendingToApproved',
    61: 'canChangeStatusFromPendingToRejected',
    62: 'canChangeStatusFromApprovedToDelivered',
    63: 'canChangeStatusFromApprovedToRejected',
    64: 'canViewPersonalId',
    65: 'canViewRelativePersonalId'
};
