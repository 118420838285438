import React, { useEffect, useState } from 'react';

import Icon from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import StyledSubsetTabFilter from './styled/SubsetTabFilter.styled';
import {
    resetDropdown,
    fetchDashboardDropdowns
} from '../../../../redux/slices/dropdown';
import FilterArrowDownIcon from '../../../../shared/theme/assets/icons/FilterArrowDownIcon';
import { SelectIcon } from '../../../../shared/theme/assets/icons/SelectIcon';
import { mapDataForDropdown } from '../../../../utils/dropdownHelper';
import { flatSelectedFilters } from '../../../utils/Dashboard/filtersUtil';
import { FiltersBox } from '../Table/FiltersBox';

const SubsetTabFilter = props => {
    const { filter } = props;
    const {
        state,
        filterValues,
        tab,
        handleFilterValueUpdate,
        handleTagRemove,
        handleFiltersReset
    } = filter;

    const dispatch = useDispatch();
    const dropdownState = useSelector(state => state.dropdown);

    const [selected, setSelected] = useState([]);
    const [isInside, setIsInside] = useState(false);
    const [filterDivVisible, setFilterDivVisible] = useState(false);

    const className = `filter-toggle-div ${
        filterDivVisible ? 'visible' : 'non-visible'
    }`;

    const buttonContent = () => {
        return (
            <div
                data-testid="filters-box-button"
                className="inner-section"
                onClick={handleFilterOnClick}
            >
                Filter
                {filterDivVisible ? (
                    <FilterArrowDownIcon />
                ) : (
                    <Icon component={SelectIcon} />
                )}
            </div>
        );
    };

    const levelsForDropdown =
        !dropdownState.isLoading && mapDataForDropdown(dropdownState.levels);
    const departmentsForDropdown =
        !dropdownState.isLoading &&
        mapDataForDropdown(dropdownState.departments);
    const locationsForDropdown =
        !dropdownState.isLoading && mapDataForDropdown(dropdownState.locations);
    const managersForDropdown =
        !dropdownState.isLoading && mapDataForDropdown(dropdownState.managers);
    const positionsForDropdown =
        !dropdownState.isLoading && mapDataForDropdown(dropdownState.positions);
    const typesOfMentormaterForDropdown =
        !dropdownState.isLoading &&
        mapDataForDropdown(dropdownState.typesOfMentormater);
    const benefitsForDropdown =
        !dropdownState.isLoading &&
        mapDataForDropdown(dropdownState.benefitTypeNames);

    useEffect(() => {
        handleDropdownDataFetch();
        return () => {
            dispatch(resetDropdown());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!dropdownState.isLoading) {
            setSelected(transformSelectedFilters);
        }
    }, [state, dropdownState.isLoading]); // TODO state

    useEffect(() => {
        const handleMouseOut = e => {
            if (filterDivVisible && !isInside) {
                setFilterDivVisible(false);
            }
        };
        document.addEventListener('click', handleMouseOut);
        return () => {
            document.removeEventListener('click', handleMouseOut);
        };
    }, [filterDivVisible, isInside]);

    //functions used to fetch & transform dropdown data
    const handleDropdownDataFetch = () => {
        dispatch(fetchDashboardDropdowns());
    };

    // filter div utilities
    const hide = () => {
        setFilterDivVisible(false);
    };

    const handleFilterOnClick = () => {
        setFilterDivVisible(!filterDivVisible);
    };

    const transformSelectedFilters = flatSelectedFilters(
        filterValues,
        departmentsForDropdown,
        levelsForDropdown,
        locationsForDropdown,
        managersForDropdown,
        positionsForDropdown,
        typesOfMentormaterForDropdown,
        benefitsForDropdown
    );

    return (
        <StyledSubsetTabFilter>
            <div className={className}>
                {buttonContent()}
                {filterDivVisible && (
                    <FiltersBox
                        data-testid="filters-box-container"
                        filterState={filterValues}
                        handleHide={hide}
                        handleFilterValueUpdate={handleFilterValueUpdate}
                        departments={departmentsForDropdown}
                        levels={levelsForDropdown}
                        positions={positionsForDropdown}
                        managers={managersForDropdown}
                        locations={locationsForDropdown}
                        mentormaterTypes={typesOfMentormaterForDropdown}
                        benefitTypeNames={benefitsForDropdown}
                        selectedTags={selected}
                        onTagRemove={handleTagRemove}
                        handleFiltersReset={handleFiltersReset}
                        setIsInside={setIsInside}
                        tab={tab}
                    />
                )}
            </div>
        </StyledSubsetTabFilter>
    );
};

export default SubsetTabFilter;
