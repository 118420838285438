import RichText from 'shared/components/RichText';

import { MAX_LENGTH_RICH_TEXT } from '../../../utils/shared/benefitModalHelper';

function ContactsTab({ values, handleRichTextChange, setFieldValue, errors }) {
    return (
        <RichText
            label="Contacts"
            placeholder="Benefit Contacts ..."
            value={values.benefitTypeContacts.html}
            name={'benefitTypeContacts'}
            hasError={errors?.benefitTypeContacts}
            onChange={(name, values) =>
                handleRichTextChange(name, values, setFieldValue)
            }
            maxLength={MAX_LENGTH_RICH_TEXT}
            id={'benefitTypeContacts'}
        />
    );
}

export default ContactsTab;
