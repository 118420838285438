import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PermissionsContextProvider } from 'shared/utils/permissionStore';

import App from './App';
import { configureStore } from './redux/index';
import * as serviceWorker from './registerServiceWorker';

import './index.css';

const store = configureStore();

ReactDOM.render(
    <React.StrictMode>
        <PermissionsContextProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </PermissionsContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
