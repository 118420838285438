import React from 'react';
import { useState } from 'react';

import jwtDecode from 'jwt-decode';

import { getUserPermissionsObject } from './permissionsHelper';

const permissionsDefaults = {
    canViewPeoplePage: false,
    canViewAllTab: false,
    canViewCurrentTab: false,
    canViewNewTab: false,
    canViewLeaversTab: false,
    canViewExTab: false,
    canViewPeopleDetailsPage: false,
    canExportList: false,
    canExportPeopleBenefitsList: false,

    canSeeContactInformationPhoneNumber: false,
    canSeeMainInformationTab: false,
    canSeePersonalInformationTab: false,
    canSeePersonBenefitsTab: false,
    canSeeHistoricalData: false,
    canDeletePerson: false,
    canEditPersonInformation: false,
    canAddWorkingPeriod: false,
    canEditWorkingPeriod: false,
    canDeleteWorkingPeriod: false,
    canAddBenefitToEmployee: false,

    canViewReportsPage: false,
    canViewPowerBIReportsTab: false,
    canViewBenefitsTab: false,

    canViewAdminPage: false,
    canViewBenefitTypesTab: false,
    canViewRolesTab: false,
    canViewUsersTab: false,
    canCreateNewBenefitType: false,
    canEditBenefitType: false,
    canCreateRole: false,
    canEditRole: false,
    canDeleteRole: false,
    canCreateUser: false,
    canEditUser: false,
    canDeleteUser: false,
    canViewNonFlexibleBenefitTypesTab: false,
    canCreateNonFlexibleBenefitType: false,
    canEditNonFlexibleBenefitType: false,
    canViewOffersTab: false,
    canCreateOffers: false,
    canEditOffers: false,
    canViewDiscountsTab: false,
    canCreateDiscounts: false,
    canEditDiscount: false,
    canDeleteDiscount: false,

    canViewOrgChartPage: false,

    canCreateNewPerson: false,

    canViewBenefitsPage: false,
    canViewBenefitsAllTab: false,
    canViewBenefitsNewTab: false,
    canViewBenefitsMaternityTab: false,
    canViewBenefitsLeaversTab: false,
    canExportPeopleBenefitsList: false,

    canViewRequestsPage: false,
    canViewRequestsPagePendingTab: false,
    canViewRequestsPageApprovedTab: false,
    canViewRequestsPageDeliveredTab: false,
    canViewRequestsPageRejectedTab: false,
    canViewRequestsPageCancelledTab: false,
    canViewPersonalId: false,
    canViewRelativePersonalId: false
};

const PermissionsContext = React.createContext({
    permissions: permissionsDefaults,
    load: () => {},
    delete: () => {}
});

export const PermissionsContextProvider = props => {
    let initialPermissions = { ...permissionsDefaults };
    const token = localStorage.getItem('token');

    if (token) {
        const decodedToken = jwtDecode(token);
        const permissionsFromToken = getUserPermissionsObject(
            decodedToken.permissions
        );
        initialPermissions = { ...initialPermissions, ...permissionsFromToken };
    }

    const [permissions, setPermissions] = useState(initialPermissions);

    const loadHandler = token => {
        const decodedToken = jwtDecode(token);
        const permissions = getUserPermissionsObject(decodedToken.permissions);
        setPermissions(prev => {
            return { ...prev, ...permissions };
        });
    };

    const deleteHandler = () => {
        setPermissions({ ...permissionsDefaults });
    };

    const contextValue = {
        permissions: permissions,
        load: loadHandler,
        delete: deleteHandler
    };

    return (
        <PermissionsContext.Provider value={contextValue}>
            {props.children}
        </PermissionsContext.Provider>
    );
};

export default PermissionsContext;
