/**
 * @name PredicateFunction
 * @function
 * @param {any} value Each value contained in the given object
 */

/**
 * Returns an object with the key value pairs that comply with the given predicate
 * @param {Object} toFilterObject
 * @param {PredicateFunction} predicate
 * @returns {object}
 */
export const filterObject = (toFilterObject, predicate) => {
    if (typeof toFilterObject !== 'object' || Array.isArray(toFilterObject))
        throw new Error(
            'filterObject expects an object as its first argument.'
        );

    if (typeof predicate !== 'function')
        throw new Error(
            'filterObject expects a function as its second argument.'
        );

    return Object.keys(toFilterObject)
        .filter(key => predicate(toFilterObject[key]))
        .reduce((res, key) => ((res[key] = toFilterObject[key]), res), {});
};
