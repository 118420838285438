import React from 'react';

const GoogleIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            fill="none"
            viewBox="0 0 17 17"
        >
            <g fill="#fff" clipPath="url(#clip0)">
                <path d="M15.049 6.5H13.59v1.451h-1.452V9.41h1.452v1.452h1.458V9.409H16.5V7.951h-1.451V6.5zM5.5 13.5c2.885 0 4.802-2.025 4.802-4.884 0-.327-.034-.58-.081-.832H5.5v1.719h2.838c-.116.73-.86 2.155-2.838 2.155-1.705 0-3.097-1.412-3.097-3.158s1.391-3.159 3.097-3.159c.975 0 1.623.416 1.992.771L8.85 4.809C7.976 3.991 6.85 3.5 5.5 3.5c-2.763 0-5 2.237-5 5s2.237 5 5 5z"></path>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(.5 .5)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};

export default GoogleIcon;
