/*istanbul ignore file*/
import React, { useEffect, useState, useContext } from 'react';

import jwtDecode from 'jwt-decode';
import { Helmet } from 'react-helmet';
import MicrosoftLogin from 'react-microsoft-login';
import { Redirect } from 'react-router';
import PermissionsContext from 'shared/utils/permissionStore';

import { StyledLogin } from './styled/Login.styled';
import { STATIC_ROUTES_PATHS } from '../../routes';
import { errorNotification } from '../../shared/components/Notifications';
import { Spinner } from '../../shared/components/Spinner';
import { config } from '../../utils/loginServerConfigs';
import { GoogleLoginButton } from '../components/GoogleLoginButton';
import authService from '../services/authService';

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [disableMsLogin, setDisableMsLogin] = useState(true);
    const errorTokenExp = localStorage.getItem('errorTokenExp');
    const pageTitle = 'Humate';
    const permissionsContext = useContext(PermissionsContext);

    useEffect(() => {
        if (localStorage.getItem('logout')) {
            localStorage.removeItem('logout');
        }
        handleMsLoginStatus(setDisableMsLogin);
    }, []);

    const handleShowSessionExp = () => {
        errorNotification(errorTokenExp, 5);
        localStorage.removeItem('errorTokenExp');
    };

    const authHandler = (err, data) => {
        if (!disableMsLogin) {
            (async () => {
                setIsLoading(true);
                const resData = await authService.loginWithMicrosoft(
                    data.idToken
                );
                const decodedToken = jwtDecode(data.accessToken);

                localStorage.setItem('token', resData.data.token);
                localStorage.setItem(
                    'profile',
                    JSON.stringify({
                        email: decodedToken.unique_name,
                        given_name: decodedToken.given_name,
                        name: decodedToken.name,
                        picture: resData.data.photo
                    })
                );

                permissionsContext.load(resData.data.token);
                setIsLoading(false);
            })();
        }
    };

    const handleMsLoginStatus = setDisableMsLogin => {
        //DO NOT USE THIS FOR SECRETS
        const msLoginStatus = window.DISABLE_MS_LOGIN;
        if (typeof msLoginStatus === 'boolean') {
            setDisableMsLogin(msLoginStatus);
        }
    };

    const disabledStyle = {
        pointerEvents: 'none',
        opacity: '0.6'
    };

    return (
        <>
            {!authService.getJwt() ? (
                <StyledLogin>
                    <Helmet title={pageTitle} />
                    <div className="login-layer">
                        <div className="login-box">
                            <div>
                                <div className="header-section">Humate</div>
                                <div className="slogan-section">
                                    {isLoading && <Spinner />}
                                </div>
                            </div>
                            <GoogleLoginButton
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                            />
                            <div style={disableMsLogin ? disabledStyle : {}}>
                                <MicrosoftLogin
                                    clientId={config.microsoft_client_id}
                                    authCallback={authHandler}
                                    redirectUri="/"
                                    prompt="select_account"
                                    tenantUrl={config.microsoft_tenant_url}
                                />
                            </div>
                        </div>
                    </div>
                    {errorTokenExp && handleShowSessionExp()}
                </StyledLogin>
            ) : (
                <Redirect
                    exact={true}
                    path={'/main'}
                    to={{
                        pathname: STATIC_ROUTES_PATHS.main
                    }}
                />
            )}
        </>
    );
};

export default Login;
