import React, { useMemo, useCallback, useEffect, useState } from 'react';

import discountService from 'admin/services/discountService';
import {
    handleDiscountNameOnChange,
    handleDiscountNameOnBlur,
    handleDiscountShortDescriptionOnChange,
    handleDiscountShortDescriptionOnBlur,
    handleDiscountLocationOnChange,
    handleDiscountMinimumOnChange,
    handleDiscountMinimumOnBlur,
    handleDiscountMaximumOnChange,
    handleDiscountMaximumOnBlur,
    handleDiscountHowMuchOnChange,
    handleDiscountHowMuchOnBlur,
    handleDiscountHowToUseOnChange,
    handleDiscountHowToUseOnBlur,
    handleDiscountCategoryOnSearch,
    handleDiscountCategoryOnInputKeyDown,
    handleDiscountCategoryOnChange,
    handleDiscountImageAltTextOnChange,
    handleDiscountImageAltTextOnBlur,
    handleOnImageUpload,
    handleOnDeleteImage,
    handleOnActiveStatusChange,
    handleOnVisibilityStatusChange,
    handleUploadConfirmation,
    getReplacementFile,
    getUniqueFiles,
    getFileNamesWithDates,
    getFileNameWithoutDate,
    isValidForSubmit,
    setEditModalState
} from 'admin/utils/shared/discountModalHelper';
import { Switch, Upload } from 'antd';
import { Form, Formik } from 'formik';
import dropdownService from 'people/services/dropdownService';
import { useSelector, useDispatch } from 'react-redux';
import { resetDiscount } from 'redux/slices/discount';
import { CustomInput } from 'shared/components';
import { CustomButton } from 'shared/components/Button';
import { CloseButton } from 'shared/components/CloseButton';
import { CustomMultiSelect } from 'shared/components/MultiSelect';
import RichText from 'shared/components/RichText';
import { SelectWithCreate } from 'shared/components/SelectWithCreate';
import Column from 'shared/components/styled/Column.styled';
import Row from 'shared/components/styled/Row.styled';
import { UploadImage } from 'shared/components/UploadImage';
import { UploadModal } from 'shared/components/UploadModal';
import { setClassName } from 'utils/componentPropHelpers';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import { SelectedOptions } from '../DiscountModal/SelectedOptions';
import { StyledDiscountModal } from '../DiscountModal/styled/DiscountModal.styled';

const DiscountModal = ({
    title,
    visible,
    setVisible = () => {},
    handleSubmit = () => {}
}) => {
    const dispatch = useDispatch();
    const { singleDiscountData, isEditMode, isLoadingFetchSingle } =
        useSelector(state => state.discount);
    const [errors, setErrors] = useState({});
    const [locations, setLocations] = useState([]);
    const [selectedDiscountLocations, setSelectedDiscountLocations] = useState(
        []
    );

    const [discounCategorySearchValue, setDiscountCategorySearchValue] =
        useState('');
    const [categories, setCategories] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [
        isDiscountShortDescriptionInFocus,
        setIsDiscountShortDescriptionInFocus
    ] = useState(false);

    const [isDiscountNameInFocus, setIsDiscountNameInFocus] = useState(false);
    const [isDiscountMinimumInFocus, setIsDiscountMinimumInFocus] =
        useState(false);
    const [isDiscountMaximumInFocus, setIsDiscountMaximumInFocus] =
        useState(false);
    const [discountSearchedCategory, setDiscountSearchedCategory] =
        useState('');
    const [isDiscountImageAltTextInFocus, setIsDiscountImageAltTextInFocus] =
        useState(false);
    const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
        useState(false);
    const [replacementFiles, setReplacementFiles] = useState();
    const [filesForDeletion, setFilesForDeletion] = useState([]);
    const [hasStateChanged, setHasStateChanged] = useState(false);

    const handlePreview = file => {
        if (file.isNew) {
            return;
        }

        const newWindow = window.open(
            file.url,
            '_blank',
            'noopener,noreferrer'
        );
        if (newWindow) newWindow.opener = null;
    };

    const fileProps = useMemo(() => {
        return {
            showUploadList: {
                showRemoveIcon: true,
                showPreviewIcon: true
            },
            onRemove: file => {
                setHasStateChanged(true);
                file.name = getFileNameWithoutDate(file.name);
                setFilesToBeUploaded(previousFilesToBeUploaded => {
                    setFilesForDeletion(
                        previousFilesToBeUploaded
                            .filter(f => file.url && f.name === file.name)
                            .map(f => f.id)
                    );
                    return previousFilesToBeUploaded.filter(
                        f => f.name !== file.name
                    );
                });
            },
            fileList: getFileNamesWithDates(filesToBeUploaded),
            handlePreview: handlePreview
        };
    }, [filesToBeUploaded]);

    const onRemoveFiles = file => {
        if (file?.name) {
            setFileList(prev => prev.filter(f => f.name !== file.name));
        } else {
            setFileList([]);
            setIsUploadModalVisible(false);
        }
        return false;
    };

    const beforeUploadFiles = useCallback(
        file => {
            const replacementFile = getReplacementFile(
                file,
                fileList,
                filesToBeUploaded
            );

            const existingFileNeedsReplacing = replacementFile !== null;

            if (existingFileNeedsReplacing) {
                setReplacementFiles(prev => {
                    const previousFiles = prev ?? [];
                    return [
                        replacementFile,
                        ...getUniqueFiles(replacementFile, previousFiles)
                    ];
                });

                setIsConfirmationModalVisible(true);

                setFileList(prev => {
                    const previousFiles = prev ?? [];
                    return [
                        replacementFile,
                        ...getUniqueFiles(replacementFile, previousFiles)
                    ];
                });

                return false;
            }

            setFileList(prev => {
                return [...prev, file];
            });

            return false;
        },
        [fileList, filesToBeUploaded]
    );

    const onUploadClick = setFieldValue => {
        if (fileList?.length > 0) {
            setHasStateChanged(true);
            const newFileNames = fileList.map(fl => fl.name);
            const existingFilesWithoutCopies = filesToBeUploaded.filter(
                p => !newFileNames.includes(p.name)
            );

            let uniqueFiles = [...fileList];

            if (existingFilesWithoutCopies.length > 0) {
                uniqueFiles = uniqueFiles.concat(existingFilesWithoutCopies);
            }

            setFilesToBeUploaded(uniqueFiles);
            setReplacementFiles([]);
            setFieldValue('discountFiles', uniqueFiles);
            onRemoveFiles();
        }
    };

    const getLocationsForDropdown = async () => {
        const locations = await dropdownService.getDiscountLocations();
        setLocations(mapDataForDropdown(locations.data));
    };

    const getCategoriesForDropdown = useCallback(async () => {
        const categories = await discountService.getCategories();
        setCategories(mapDataForDropdown(categories.data));
    }, []);

    useEffect(() => {
        getLocationsForDropdown();
        getCategoriesForDropdown();
    }, []);

    useEffect(() => {
        if (isEditMode && singleDiscountData.discountName) {
            setEditModalState(
                singleDiscountData,
                setSelectedDiscountLocations,
                setFilesToBeUploaded,
                setDiscountCategorySearchValue,
                setDiscountSearchedCategory,
                setFilesForDeletion
            );
        }
    }, [singleDiscountData]);

    const renderTitle = () => [
        <div className="custom-modal-header" key="modal-title">
            {title}
            <CloseButton handleClose={() => handleCloseModal(false)} />
        </div>
    ];

    const renderFooter = formikValues => (
        <div className="custom-modal-footer" key="modal-footer">
            <div className="button-section">
                <CustomButton
                    text="Close"
                    type="normal footer-button"
                    onClick={() => handleCloseModal(false)}
                    disabled={false}
                />
            </div>
            <div className="button-section">
                <CustomButton
                    text={'Save'}
                    type="filled footer-button"
                    onClick={() => handleSubmit(formikValues, filesForDeletion)}
                    disabled={useMemo(
                        () =>
                            isValidForSubmit(
                                errors,
                                isEditMode,
                                hasStateChanged
                            ),
                        [errors, isEditMode, hasStateChanged]
                    )}
                />
            </div>
        </div>
    );

    const getUploadedFilesCount = () =>
        `Uploaded files: ${
            filesToBeUploaded.filter(x => x?.createdOnUtc)?.length ?? 0
        }`;

    const MAX_LENGTH_RICH_TEXT = 1000;

    const setDiscountNameClassName = () =>
        setClassName(!isDiscountNameInFocus && errors?.discountName);

    const setShortDescriptionClassName = () =>
        setClassName(
            !isDiscountShortDescriptionInFocus &&
                errors?.discountShortDescription
        );

    const setDiscountMinimumClassName = () =>
        setClassName(!isDiscountMinimumInFocus && errors?.discountMinimum);

    const setDiscountMaxiumClassName = () =>
        setClassName(!isDiscountMaximumInFocus && errors?.discountMaximum);

    const setDiscountImageAltTextClassName = () =>
        setClassName(
            !isDiscountImageAltTextInFocus && errors?.discountImageAltText
        );

    const handleCloseModal = visible => {
        dispatch(resetDiscount());
        setVisible(visible);
    };

    const handleDiscountCategoryOnBlur = (setErrors, values) => {
        setErrors(prev => {
            return {
                ...prev,
                ...{
                    discountCategory: !values.discountCategory
                        ? 'Discount category is required.'
                        : undefined
                }
            };
        });
    };

    const handleDiscountLocationOnClick = (isMenuOpened, setErrors, values) => {
        setErrors(prev => {
            return {
                ...prev,
                ...{
                    discountLocations:
                        !isMenuOpened && values.discountLocations.length === 0
                            ? 'Discount location is required.'
                            : undefined
                }
            };
        });
    };

    return (
        <Formik initialValues={singleDiscountData} enableReinitialize={true}>
            {props => {
                const { values, setFieldValue } = props;
                return (
                    <StyledDiscountModal
                        visible={
                            visible &&
                            !isLoadingFetchSingle &&
                            locations.length > 0 &&
                            categories.length > 0
                        }
                        setVisible={() => handleCloseModal(false)}
                        title={renderTitle()}
                        footer={renderFooter(values)}
                        data-testid="discount-modal"
                    >
                        <Form>
                            <UploadImage
                                imageText="Select Image *"
                                externalError={errors?.discountImageError}
                                onUpload={file =>
                                    handleOnImageUpload(
                                        file,
                                        setFieldValue,
                                        setErrors,
                                        setHasStateChanged
                                    )
                                }
                                initialImage={values.discountImage}
                                maxSize={MAX_IMAGE_SIZE}
                                onDelete={useCallback(
                                    () =>
                                        handleOnDeleteImage(
                                            setFieldValue,
                                            setErrors
                                        ),
                                    [setErrors, setFieldValue]
                                )}
                            />

                            <CustomInput
                                label="Image Alt Text *"
                                placeholder="Image Alt Text..."
                                value={values.discountImageAltText}
                                name="discountImageAltText"
                                className={setDiscountImageAltTextClassName()}
                                isNormalChange={false}
                                onChange={(name, value) =>
                                    handleDiscountImageAltTextOnChange(
                                        name,
                                        value,
                                        setFieldValue,
                                        setIsDiscountImageAltTextInFocus,
                                        setHasStateChanged
                                    )
                                }
                                onBlur={() =>
                                    handleDiscountImageAltTextOnBlur(
                                        values.discountImageAltText,
                                        setErrors,
                                        setIsDiscountImageAltTextInFocus
                                    )
                                }
                                allowClear={true}
                            />
                            <CustomInput
                                className={setDiscountNameClassName()}
                                label="Discount *"
                                placeholder="Discount..."
                                isNormalChange={false}
                                allowClear={true}
                                value={values.discountName}
                                name={'discountName'}
                                onChange={(name, value) =>
                                    handleDiscountNameOnChange(
                                        name,
                                        value,
                                        setFieldValue,
                                        setIsDiscountNameInFocus,
                                        setHasStateChanged
                                    )
                                }
                                onBlur={() =>
                                    handleDiscountNameOnBlur(
                                        values.discountName,
                                        setErrors,
                                        setIsDiscountNameInFocus
                                    )
                                }
                            />
                            <CustomInput
                                label="Short Description *"
                                placeholder="Short description..."
                                value={values.discountShortDescription}
                                name="discountShortDescription"
                                className={setShortDescriptionClassName()}
                                isNormalChange={false}
                                onChange={(name, value) =>
                                    handleDiscountShortDescriptionOnChange(
                                        name,
                                        value,
                                        setFieldValue,
                                        setIsDiscountShortDescriptionInFocus,
                                        setHasStateChanged
                                    )
                                }
                                onBlur={() =>
                                    handleDiscountShortDescriptionOnBlur(
                                        values.discountShortDescription,
                                        setErrors,
                                        setIsDiscountShortDescriptionInFocus
                                    )
                                }
                                allowClear={true}
                            />

                            <SelectWithCreate
                                label="Category *"
                                name={'discountCategory'}
                                className={setClassName(
                                    errors?.discountCategory
                                )}
                                value={values.discountCategory}
                                placeholder={'Category...'}
                                onChange={(name, value) =>
                                    handleDiscountCategoryOnChange(
                                        name,
                                        value,
                                        categories,
                                        setFieldValue,
                                        setErrors,
                                        setHasStateChanged
                                    )
                                }
                                options={categories}
                                showSearch={true}
                                onSearch={value =>
                                    handleDiscountCategoryOnSearch(
                                        value,
                                        setDiscountCategorySearchValue,
                                        setDiscountSearchedCategory,
                                        setErrors
                                    )
                                }
                                onInputKeyDown={event =>
                                    handleDiscountCategoryOnInputKeyDown(
                                        event,
                                        setFieldValue,
                                        discountSearchedCategory,
                                        setDiscountSearchedCategory,
                                        categories,
                                        setCategories,
                                        setHasStateChanged
                                    )
                                }
                                searchValue={discounCategorySearchValue}
                                onBlur={() =>
                                    handleDiscountCategoryOnBlur(
                                        setErrors,
                                        values
                                    )
                                }
                            />
                            <CustomMultiSelect
                                canCreate={true}
                                label="Location *"
                                className={setClassName(
                                    errors?.discountLocations
                                )}
                                hasSelectAll={false}
                                disableSearch={false}
                                value={values.discountLocations}
                                name={'discountLocations'}
                                options={locations}
                                onChange={(name, _, values) =>
                                    handleDiscountLocationOnChange(
                                        name,
                                        values,
                                        setFieldValue,
                                        setErrors,
                                        setHasStateChanged
                                    )
                                }
                                onSelection={setSelectedDiscountLocations}
                                selected={selectedDiscountLocations}
                                placeholder={'Location...'}
                                onClick={isMenuOpened =>
                                    handleDiscountLocationOnClick(
                                        isMenuOpened,
                                        setErrors,
                                        values
                                    )
                                }
                            />

                            {selectedDiscountLocations && (
                                <SelectedOptions
                                    options={selectedDiscountLocations}
                                    values={values.discountLocations}
                                    onChange={values => {
                                        handleDiscountLocationOnChange(
                                            'discountLocations',
                                            values,
                                            setFieldValue,
                                            setErrors,
                                            setHasStateChanged
                                        );

                                        setSelectedDiscountLocations(values);
                                    }}
                                    handleValues={setFieldValue}
                                />
                            )}

                            <CustomInput
                                label="Website"
                                placeholder="Website..."
                                value={values.discountWebsite}
                                name={'discountWebsite'}
                                isNormalChange={false}
                                onChange={(name, val) => {
                                    setFieldValue(name, val);
                                    setHasStateChanged(true);
                                }}
                                allowClear={true}
                            />

                            <Row justifyContent="space-between">
                                <Column width="40%">
                                    <CustomInput
                                        className={setDiscountMinimumClassName()}
                                        label="Discount minimum % *"
                                        placeholder="Discount minimum..."
                                        value={values.discountMinimum}
                                        name={'discountMinimum'}
                                        isNormalChange={false}
                                        onChange={(name, value) =>
                                            handleDiscountMinimumOnChange(
                                                name,
                                                value,
                                                setFieldValue,
                                                setIsDiscountMinimumInFocus,
                                                setHasStateChanged
                                            )
                                        }
                                        onBlur={() =>
                                            handleDiscountMinimumOnBlur(
                                                values.discountMinimum,
                                                values.discountMaximum,
                                                setErrors,
                                                setIsDiscountMinimumInFocus
                                            )
                                        }
                                        allowClear={true}
                                        type="number"
                                    />
                                </Column>
                                <Column width="40%">
                                    <CustomInput
                                        label="Discount maximum %"
                                        placeholder="Discount maximum..."
                                        value={values.discountMaximum}
                                        name={'discountMaximum'}
                                        className={setDiscountMaxiumClassName()}
                                        isNormalChange={false}
                                        onChange={(name, value) =>
                                            handleDiscountMaximumOnChange(
                                                name,
                                                value,
                                                setFieldValue,
                                                setIsDiscountMaximumInFocus,
                                                setHasStateChanged
                                            )
                                        }
                                        onBlur={() =>
                                            handleDiscountMaximumOnBlur(
                                                values.discountMinimum,
                                                values.discountMaximum,
                                                setErrors,
                                                setIsDiscountMaximumInFocus
                                            )
                                        }
                                        allowClear={true}
                                        type="number"
                                    />
                                </Column>
                            </Row>

                            <RichText
                                label="How much *"
                                placeholder="How much..."
                                value={values.discountHowMuch.html}
                                name="discountHowMuch"
                                hasError={errors?.discountHowMuch}
                                onChange={(name, value) =>
                                    handleDiscountHowMuchOnChange(
                                        name,
                                        value,
                                        setFieldValue,
                                        setErrors,
                                        setHasStateChanged
                                    )
                                }
                                onBlur={() =>
                                    handleDiscountHowMuchOnBlur(
                                        values.discountHowMuch,
                                        setErrors
                                    )
                                }
                                maxLength={MAX_LENGTH_RICH_TEXT}
                                id={'discountHowMuch'}
                            />

                            <RichText
                                label="How to use *"
                                placeholder="How to use..."
                                value={values.discountHowToUse.html}
                                name="discountHowToUse"
                                hasError={errors?.discountHowToUse}
                                onChange={(name, value) =>
                                    handleDiscountHowToUseOnChange(
                                        name,
                                        value,
                                        setFieldValue,
                                        setErrors,
                                        setHasStateChanged
                                    )
                                }
                                onBlur={() =>
                                    handleDiscountHowToUseOnBlur(
                                        values.discountHowToUse,
                                        setErrors
                                    )
                                }
                                maxLength={MAX_LENGTH_RICH_TEXT}
                                id={'discountHowToUse'}
                            />

                            <UploadModal
                                title={'Upload document(s)'}
                                multiple={true}
                                visible={isUploadModalVisible}
                                onRemove={onRemoveFiles}
                                beforeUpload={beforeUploadFiles}
                                fileSelectorButtonText={'Browse files'}
                                fileList={fileList}
                                onUploadClick={() =>
                                    onUploadClick(setFieldValue)
                                }
                                isConfirmationModalVisible={
                                    isConfirmationModalVisible
                                }
                                handleConfirmation={() =>
                                    handleUploadConfirmation(
                                        replacementFiles,
                                        fileList,
                                        setFileList,
                                        setIsConfirmationModalVisible,
                                        setReplacementFiles
                                    )
                                }
                                setConfirmationModalVisible={
                                    setIsConfirmationModalVisible
                                }
                                closeConfirmationModalCallback={() => {
                                    setFileList(currentFileListFiles => {
                                        return currentFileListFiles.filter(
                                            cff =>
                                                !filesToBeUploaded
                                                    .map(ftbu => ftbu.name)
                                                    .includes(cff.name)
                                        );
                                    });
                                    setReplacementFiles([]);

                                    setIsConfirmationModalVisible(false);
                                }}
                            />

                            <CustomButton
                                width="100%"
                                type="normal"
                                text="Upload documents"
                                onClick={() => setIsUploadModalVisible(true)}
                            />

                            <div className="custom-upload-div">
                                <div className="custom-upload-label">
                                    {getUploadedFilesCount()}
                                </div>
                                <Upload {...fileProps} />
                            </div>

                            <Row justifyContent="space-between">
                                <span className="input-label">
                                    Make this discount active in Humate
                                </span>
                                <Row>
                                    <Switch
                                        checked={values.discountIsActive}
                                        checkedChildren="YES"
                                        unCheckedChildren="NO"
                                        onChange={value =>
                                            handleOnActiveStatusChange(
                                                value,
                                                setFieldValue,
                                                setHasStateChanged
                                            )
                                        }
                                    />
                                </Row>
                            </Row>

                            <Row justifyContent="space-between">
                                <span className="input-label">
                                    Make this discount visible on MentorNet
                                </span>
                                <Row>
                                    <Switch
                                        checked={
                                            values.discountIsActive &&
                                            values.discountVisible
                                        }
                                        checkedChildren="YES"
                                        unCheckedChildren="NO"
                                        onChange={value =>
                                            handleOnVisibilityStatusChange(
                                                value,
                                                setFieldValue,
                                                setHasStateChanged
                                            )
                                        }
                                        disabled={!values.discountIsActive}
                                    />
                                </Row>
                            </Row>
                        </Form>
                    </StyledDiscountModal>
                );
            }}
        </Formik>
    );
};

const MAX_IMAGE_SIZE = 5242880; // 1024*1024*5 -> 5MB

export { DiscountModal };
