import { combineReducers } from 'redux';

import { benefitReducer } from './slices/benefit';
import { benefitReportReducer } from './slices/benefitReport';
import { benefitTypeReducer } from './slices/benefitType';
import { discountReducer } from './slices/discount';
import { dropdownReducer } from './slices/dropdown';
import { historyReducer } from './slices/history';
import { offerReducer } from './slices/offer';
import { peopleReducer } from './slices/people';
import { relativeMemberReducer } from './slices/relativeMember';
import { requestReducer } from './slices/request';
import { roleReducer } from './slices/role';
import { userReducer } from './slices/user';
import { workingPeriodReducer } from './slices/workingPeriod';

const appReducer = combineReducers({
    offer: offerReducer,
    people: peopleReducer,
    discount: discountReducer,
    dropdown: dropdownReducer,
    workingPeriods: workingPeriodReducer,
    history: historyReducer,
    relativeMembers: relativeMemberReducer,
    benefits: benefitReducer,
    benefitType: benefitTypeReducer,
    benefitReport: benefitReportReducer,
    role: roleReducer,
    user: userReducer,
    request: requestReducer
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export { rootReducer };
