/*istanbul ignore file*/
import React, { useContext } from 'react';

import jwtDecode from 'jwt-decode';
import $script from 'scriptjs';
import PermissionsContext from 'shared/utils/permissionStore';

import { StyledLoginButton } from './styled/LoginButton.styled';
import { errorNotification } from '../../shared/components/Notifications';
import GoogleIcon from '../../shared/theme/assets/icons/GoogleIcon';
import { config } from '../../utils/loginServerConfigs';
import authService from '../services/authService';

const GoogleLoginButton = ({ isLoading, setIsLoading }) => {
    const permissionsContext = useContext(PermissionsContext);

    $script('https://accounts.google.com/gsi/client', 'gsi-script');

    const handleCredentialResponse = res => {
        const credential = res.credential;
        (async () => {
            try {
                setIsLoading(true);
                const resData = await authService.login(credential);
                const decodedToken = jwtDecode(credential);
                //if you need more props that are from token get them from here
                localStorage.setItem('token', resData.data.token);
                localStorage.setItem(
                    'profile',
                    JSON.stringify({
                        email: decodedToken.email,
                        given_name: decodedToken.given_name,
                        name: decodedToken.name,
                        picture: decodedToken.picture
                    })
                );

                permissionsContext.load(resData.data.token);

                setIsLoading(false);
            } catch (error) {
                errorNotification('Not authorized.', 5);
                setIsLoading(false);
            }
        })();
    };

    const handleClick = () => {
        $script.ready('gsi-script', () => {
            try {
                google.accounts.id.initialize({
                    client_id: config.client_id,
                    callback: handleCredentialResponse
                });

                google.accounts.id.prompt(notification => {
                    if (
                        notification.getNotDisplayedReason() ==
                            'suppressed_by_user' ||
                        notification.isSkippedMoment()
                    ) {
                        document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC`;
                        google.accounts.id.prompt();
                    }

                    if (
                        notification.getNotDisplayedReason() ==
                        'opt_out_or_no_session'
                    ) {
                        google.accounts.id.renderButton(
                            document.getElementById('gsi-button'),
                            {
                                type: 'standard'
                            }
                        );
                        document.querySelector('#button-label').click();
                    }
                });
            } catch (error) {
                // something happened
                // eslint-disable-next-line no-console
                console.error(error);
            }
        });
    };

    return (
        <>
            <div id="gsi-button" style={{ display: 'none' }}></div>
            <StyledLoginButton
                text={'Login with Google'}
                disabled={isLoading}
                iconComponent={GoogleIcon}
                onClick={handleClick}
            />
        </>
    );
};

export { GoogleLoginButton };
