import { CustomModal } from 'shared/components/Modal';
import styled from 'styled-components';

const StyledUploadFileModal = styled(CustomModal)`
    width: 338px !important;
    .custom-modal-header {
        display: flex;
        justify-content: space-between;
    }
    .section-inputs {
        display: block;
    }
    .ant-modal-footer {
        margin-left: 34px;
        margin-right: 34px;
        padding: 20px 0px 20px 0px;
    }
    .custom-modal-footer {
        display: flex;
        justify-content: center;
        .footer-button {
            width: 100% !important;
            height: 100% !important;
            .button-inner {
                width: 130px;
                padding: 12px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
            }
        }
        .button-section:first-child {
            padding-right: 10px;
        }
    }
    .modal-body-section {
        padding-top: 20px;
        border-bottom: 1px solid var(--whiteGray);
        .section-title {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            padding-bottom: 20px;
        }
    }
    .button {
        width: 270px;
    }
    .modal-upload-section {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--whiteGray);
        .section-title {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            padding-bottom: 20px;
        }
    }
    .ant-upload-list-item-card-actions-btn {
        opacity: 1;
    }
    @media screen and (max-width: 768px) {
        width: 320px !important;
    }
    @media screen and (max-width: 425px) {
        width: 320px !important;
    }
`;

export { StyledUploadFileModal };
