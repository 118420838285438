import React, { useState, useRef, useEffect } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import StyledRichText from './styled/RichText.styled';

const MODULES = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // Options for text styling
        [{ align: [] }],

        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],

        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],

        ['clean'] // Option to clear formatting
    ]
};

const RichText = ({
    label,
    placeholder,
    value,
    name,
    className,
    onChange,
    maxLength,
    id,
    hasError,
    onBlur = () => {}
}) => {
    const quillRef = useRef(null);
    const reactQuillRef = useRef(null);
    const [counter, setCounter] = useState(0);

    const classes = [className ? className : '', hasError ? 'error' : ''].join(
        ' '
    );

    // Used to fix a bug where on the first render the ReactQuill component somehow
    // has it's onChange method triggered which results to clearing all previously applied formatting.
    // Applies to cases where we have an initial value coming from props.
    // Source https://github.com/zenoamaro/react-quill/issues/814
    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => setIsInitialRender(false), []);

    useEffect(() => {
        if (typeof reactQuillRef.current?.getEditor !== 'function') return;
        quillRef.current = reactQuillRef.current.getEditor();
        const textLength = quillRef.current.root.innerText
            ?.replace(/[\t\n]*/g, '')
            .trim().length;

        setCounter(textLength || 0);
    }, [value]);

    const handleChange = (content, _delta, _source, editor) => {
        onChange(name, {
            html: content,
            plainText: editor
                .getText()
                .replace(/[\t\n]*/g, '')
                .trim()
        });
    };

    return (
        !isInitialRender && (
            <StyledRichText className={classes}>
                {label && <span className="input-label">{label}</span>}
                <ReactQuill
                    id={id}
                    ref={el => {
                        reactQuillRef.current = el;
                    }}
                    theme="snow"
                    value={value}
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={onBlur}
                    modules={MODULES}
                />
                {!!maxLength && (
                    <div className="counter" id="counter">
                        {counter} / {maxLength}
                    </div>
                )}
            </StyledRichText>
        )
    );
};

export default RichText;
