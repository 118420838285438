import moment from 'moment';
import { getFormData } from 'utils/getFormData';

const MAX_LENGTH_SHORT_DESCRIPTION = 1000;
const MAX_LENGTH_IMAGE_ALT_TEXT = 120;
const MIN_LENGTH_SHORT_DESCRIPTION = 3;

const validateDiscountName = value => {
    let errors = { discountName: undefined };

    if (value == null || value?.trim() === '') {
        errors.discountName = 'Discount name is required.';
    }

    return errors;
};

const validateShortDesc = discountShortDescription => {
    let errors = { discountShortDescription: undefined };

    if (
        discountShortDescription == null ||
        discountShortDescription?.trim() === ''
    ) {
        errors = {
            ...errors,
            discountShortDescription: 'Discount short description is required.'
        };
    }

    if (
        discountShortDescription?.trim().length > MAX_LENGTH_SHORT_DESCRIPTION
    ) {
        errors = {
            ...errors,
            discountShortDescription: `Discount short Description should not exceed ${MAX_LENGTH_SHORT_DESCRIPTION} characters.`
        };
    }

    if (
        discountShortDescription?.trim().length < MIN_LENGTH_SHORT_DESCRIPTION
    ) {
        errors = {
            ...errors,
            discountShortDescription: `Discount short Description should be more than ${MIN_LENGTH_SHORT_DESCRIPTION} characters.`
        };
    }

    return errors;
};

const validateDiscountMinimum = (minimum, maximum) => {
    let errors = { discountMaximum: undefined, discountMinimum: undefined };

    if (!minimum?.toString() || minimum?.toString()?.trim() === '') {
        errors.discountMinimum = 'Discount minimum required.';
    } else if (maximum && Number(minimum) >= Number(maximum)) {
        errors.discountMinimum =
            'Discount minimum has to be smaller than the discount maximum';
        errors.discountMaximum =
            'Discount maximum has to be larger than the discount minimum';
    }

    return errors;
};

const validateDiscountMaximum = (minimum, maximum) => {
    let errors = { discountMaximum: undefined, discountMinimum: undefined };

    if (!minimum || minimum?.toString()?.trim() === '') {
        errors.discountMinimum = 'Discount minimum required.';
    } else if (maximum && Number(minimum) >= Number(maximum)) {
        errors.discountMinimum =
            'Discount minimum has to be smaller than the discount maximum';
        errors.discountMaximum =
            'Discount maximum has to be larger than the discount minimum';
    }

    return errors;
};

const validateHowMuch = value => {
    let errors = { discountHowMuch: undefined };

    if (!value?.plainText || value?.plainText.trim() === '') {
        errors.discountHowMuch = 'Discount how much required.';
    }
    return errors;
};

const validateHowToUse = value => {
    let errors = { discountHowToUse: undefined };

    if (!value?.plainText || value?.plainText.trim() === '') {
        errors.discountHowToUse = 'Discount how much required.';
    }
    return errors;
};

const validateCategory = value => {
    let errors = { discountCategory: undefined };

    if (!value?.label) {
        const numbersOnly = /[0-9]/;

        if (numbersOnly.test(value)) {
            errors.discountCategory =
                'The category name can contain only letters.';
        }

        if (!value?.trim()) {
            errors.discountCategory = 'Category name required.';
        }
    }
    return errors;
};

const validateImageAltText = discountImageAltText => {
    let errors = { discountImageAltText: undefined };

    if (discountImageAltText == null || discountImageAltText?.trim() === '') {
        errors = {
            ...errors,
            discountImageAltText: 'Discount image alt text is required.'
        };
    }

    if (discountImageAltText?.trim().length > MAX_LENGTH_IMAGE_ALT_TEXT) {
        errors = {
            ...errors,
            discountImageAltText: `Discount image alt text should not exceed ${MAX_LENGTH_IMAGE_ALT_TEXT} characters.`
        };
    }

    return errors;
};

const validateLocations = discountLocations => {
    let errors = { discountLocations: undefined };

    if (discountLocations == null || discountLocations.length === 0) {
        errors = {
            ...errors,
            discountLocations: 'Discount discount location is required.'
        };
    }

    return errors;
};

const NUMBER_OF_FIELDS_THAT_NEED_VALIDATION = 10;

export const handleDiscountNameOnChange = (
    name,
    value,
    setFieldValue,
    setIsDiscountNameInFocus,
    setHasStateChanged
) => {
    setFieldValue(name, value);
    setIsDiscountNameInFocus(true);
    setHasStateChanged(true);
};

export const handleDiscountNameOnBlur = (
    value,
    setErrors,
    setIsDiscountNameInFocus
) => {
    const discountNameErrors = validateDiscountName(value);
    setErrors(prev => {
        return { ...prev, ...discountNameErrors };
    });
    setIsDiscountNameInFocus(false);
};

export const handleDiscountMinimumOnChange = (
    name,
    value,
    setFieldValue,
    setIsDiscountMinimumInFocus,
    setHasStateChanged
) => {
    setFieldValue(name, value);
    setIsDiscountMinimumInFocus(true);
    setHasStateChanged(true);
};

export const handleDiscountMinimumOnBlur = (
    discountMinimum,
    discountMaximum,
    setErrors,
    setIsDiscountMinimumInFocus
) => {
    const discountRangeErrors = validateDiscountMinimum(
        discountMinimum,
        discountMaximum
    );
    setErrors(prev => {
        return { ...prev, ...discountRangeErrors };
    });
    setIsDiscountMinimumInFocus(false);
};

export const handleDiscountMaximumOnChange = (
    name,
    value,
    setFieldValue,
    setIsDiscountMaximumInFocus,
    setHasStateChanged
) => {
    setFieldValue(name, value);
    setIsDiscountMaximumInFocus(true);
    setHasStateChanged(true);
};

export const handleDiscountMaximumOnBlur = (
    discountMinimum,
    discountMaximum,
    setErrors,
    setIsDiscountMaximumInFocus
) => {
    const discountRangeErrors = validateDiscountMaximum(
        discountMinimum,
        discountMaximum
    );
    setErrors(prev => {
        return { ...prev, ...discountRangeErrors };
    });
    setIsDiscountMaximumInFocus(false);
};

export const handleDiscountShortDescriptionOnChange = (
    name,
    value,
    setFieldValue,
    setIsDiscountShortDescriptionInFocus,
    setHasStateChanged
) => {
    setFieldValue(name, value);
    setIsDiscountShortDescriptionInFocus(true);
    setHasStateChanged(true);
};

export const handleDiscountShortDescriptionOnBlur = (
    value,
    setErrors,
    setIsDiscountShortDescriptionInFocus
) => {
    const discountShortDescriptionErrors = validateShortDesc(value);
    setErrors(prev => {
        return { ...prev, ...discountShortDescriptionErrors };
    });
    setIsDiscountShortDescriptionInFocus(false);
};

export const handleDiscountLocationOnChange = (
    name,
    values,
    setFieldValue,
    setErrors,
    setHasStateChanged
) => {
    setHasStateChanged(true);
    const augmentedValues = values.map(value => {
        return {
            name: value.label,
            isNew: value.__isNew__ ?? false,
            id: value.__isNew__ ? null : value.value
        };
    });

    setErrors(prev => {
        return { ...prev, ...validateLocations(augmentedValues) };
    });

    setFieldValue(name, augmentedValues);
};

export const handleDiscountCategoryKeyDown = (
    keyboardEvent,
    setFieldValue,
    discountSearchedCategory,
    discountCategoryOptions
) => {
    const optionExists = discountCategoryOptions
        .map(d => d.label)
        .includes(discountSearchedCategory);

    if (keyboardEvent.key === 'Enter' && !optionExists) {
        discountCategoryOptions.unshift({
            label: discountSearchedCategory,
            value: undefined
        });
        setFieldValue('discountCategory', discountSearchedCategory);
    }
};

export const handleDiscountHowMuchOnChange = (
    name,
    value,
    setFieldValue,
    setErrors,
    setHasStateChanged
) => {
    setHasStateChanged(true);
    setFieldValue(name, value);
    setErrors(prev => {
        return { ...prev, discountHowMuch: undefined };
    });
};

export const handleDiscountHowMuchOnBlur = (value, setErrors) => {
    const discountHowMuchErrors = validateHowMuch(value);
    setErrors(prev => {
        return { ...prev, ...discountHowMuchErrors };
    });
};

export const handleDiscountHowToUseOnChange = (
    name,
    value,
    setFieldValue,
    setErrors,
    setHasStateChanged
) => {
    setHasStateChanged(true);
    setFieldValue(name, value);
    setErrors(prev => {
        return { ...prev, discountHowToUse: undefined };
    });
};

export const handleDiscountHowToUseOnBlur = (value, setErrors) => {
    const discountHowToUseErrors = validateHowToUse(value);
    setErrors(prev => {
        return { ...prev, ...discountHowToUseErrors };
    });
};

export const handleDiscountCategoryOnChange = (
    name,
    val,
    discountCategoryOptions,
    setFieldValue,
    setErrors,
    setHasStateChanged
) => {
    setHasStateChanged(true);
    setFieldValue(
        name,
        discountCategoryOptions.find(x => x.value === val)
    );

    setErrors(prev => {
        return { ...prev, ...{ discountCategory: undefined } };
    });
};

export const handleDiscountCategoryOnSearch = (
    value,
    setDiscountCategorySearchValue,
    setDiscountSearchedCategory,
    setErrors
) => {
    const discountCategoryErrors = validateCategory(value);

    if (
        discountCategoryErrors.discountCategory ===
        'The category name can contain only letters.'
    ) {
        return;
    }

    if (discountCategoryErrors.discountCategory === 'Category name required.') {
        setDiscountCategorySearchValue(''); //necessary for fully clearing the value with backspace
        return;
    }

    setDiscountCategorySearchValue(value?.label ?? value);
    setDiscountSearchedCategory({
        label: value?.label ?? value,
        value: value?.value ?? undefined
    });

    setErrors(prev => {
        return { ...prev, ...discountCategoryErrors };
    });
};

export const handleDiscountCategoryOnInputKeyDown = (
    keyboardEvent,
    setFieldValue,
    discountSearchedCategory,
    setDiscountSearchedCategory,
    discountCategoryOptions,
    setDiscountCategoryOptions,
    setHasStateChanged
) => {
    const existingOption = discountCategoryOptions.find(
        x => discountSearchedCategory.label === x.label
    );

    if (keyboardEvent.key === 'Enter' && discountSearchedCategory.label) {
        if (existingOption) {
            setFieldValue('discountCategory', existingOption);
        } else {
            setDiscountCategoryOptions(existing => [
                discountSearchedCategory,
                ...existing
            ]);
            setFieldValue('discountCategory', discountSearchedCategory);
        }
        setDiscountSearchedCategory('');
        setHasStateChanged(true);
    }
};

export const handleDiscountImageAltTextOnChange = (
    name,
    value,
    setFieldValue,
    setIsDiscountImageAltTextInFocus,
    setHasStateChanged
) => {
    setFieldValue(name, value);
    setIsDiscountImageAltTextInFocus(true);
    setHasStateChanged(true);
};

export const handleDiscountImageAltTextOnBlur = (
    value,
    setErrors,
    setIsDiscountImageAltTextInFocus
) => {
    const discountImageAltTextErrors = validateImageAltText(value);
    setErrors(prev => {
        return { ...prev, ...discountImageAltTextErrors };
    });
    setIsDiscountImageAltTextInFocus(false);
};

export const handleOnImageUpload = (
    file,
    setFieldValue,
    setErrors,
    setHasStateChanged
) => {
    setFieldValue('discountImage', file);
    setErrors(prev => {
        return {
            ...prev,
            discountImageError: undefined
        };
    });
    setHasStateChanged(true);
};

export const handleOnDeleteImage = (setFieldValue, setErrors) => {
    setFieldValue('deleteImage', true);
    setErrors(prev => {
        return {
            ...prev,
            discountImageError: 'Please upload an image.'
        };
    });
};

export const handleOnActiveStatusChange = (
    isActive,
    setFieldValue,
    setHasStateChanged
) => {
    setFieldValue('discountIsActive', isActive);

    if (!isActive) {
        setFieldValue('discountVisible', false);
    }

    setHasStateChanged(true);
};

export const handleOnVisibilityStatusChange = (
    value,
    setFieldValue,
    setHasStateChanged
) => {
    setFieldValue('discountVisible', value);
    setHasStateChanged(true);
};

export const handleUploadConfirmation = (
    replacementFiles,
    fileList,
    setFileList,
    setIsConfirmationModalVisible,
    setReplacementFiles
) => {
    const filesNeedReplacing = replacementFiles?.length > 0;

    if (filesNeedReplacing) {
        const replacementFileNames = replacementFiles.map(rf => rf.name);
        const newFiles =
            fileList?.filter(f => !replacementFileNames.includes(f.name)) ?? [];
        setFileList([...newFiles, ...replacementFiles]);
        setIsConfirmationModalVisible(false);
        setReplacementFiles([]);
        return;
    }
};

export const getFileNameWithDate = file => {
    const nameParts = file.name.split('.');
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    const formattedDate = `[${month}/${day}/${year}]`;
    const newName = `${nameParts[0]} ${formattedDate}.${nameParts[1]}`;
    return { ...file, ...{ name: newName } };
};

export const getReplacementFile = (file, fileList, filesToBeUploaded) => {
    const existingFileNames = [...(fileList ?? []), ...filesToBeUploaded].map(
        ef => ef.name
    );

    if (
        existingFileNames?.some(
            existingFileName => existingFileName === file.name
        )
    ) {
        return file;
    }

    return null;
};

export const getUniqueFiles = (replacementFile, files) => {
    return files.filter(
        f =>
            getFileNameWithoutDate(f.name) !==
            getFileNameWithoutDate(replacementFile.name)
    );
};

export const getSelectedLocationOptions = discountLocations =>
    discountLocations.map(x => {
        return { value: x.id, label: x.name };
    });

export const getFileNamesWithDates = fileList => {
    return (
        fileList?.map(file => {
            return {
                ...file,
                name: file.createdOnUtc
                    ? file.name +
                      ' (' +
                      moment(file.createdOnUtc).format('MM/DD/YYYY') +
                      ')'
                    : file.name
            };
        }) ?? []
    );
};

export const getFileNameWithoutDate = fileName => {
    const sliceEnd = fileName.indexOf(' (');
    return fileName.slice(0, sliceEnd === -1 ? fileName.length : sliceEnd);
};

export const isValidForSubmit = (errors, isEditMode, hasStateChanged) => {
    const allValidationsCompleted =
        errors &&
        Object.values(errors).length >= NUMBER_OF_FIELDS_THAT_NEED_VALIDATION;

    const allValidationsPassed =
        errors && Object.values(errors).every(v => v === undefined);

    return isEditMode
        ? !(allValidationsPassed && hasStateChanged)
        : !(allValidationsCompleted && allValidationsPassed);
};

export const handleSubmit = (
    editModalDiscountId,
    requestData,
    filesForDeletion,
    dispatch,
    createDiscount,
    updateDiscount,
    setIsDiscountModalOpen
) => {
    const isEditMode = typeof editModalDiscountId !== 'undefined';
    const mappedData = {};
    mappedData.name = requestData.discountName;
    mappedData.shortDescription = requestData.discountShortDescription;
    mappedData.categoryName = requestData.discountCategory.label;
    mappedData.categoryId = requestData.discountCategory.value;
    mappedData.minimum = requestData.discountMinimum;
    mappedData.website = requestData.discountWebsite;
    mappedData.howToUse = requestData.discountHowToUse.html;
    mappedData.howMuch = requestData.discountHowMuch.html;
    mappedData.active = requestData.discountIsActive;
    mappedData.visible = requestData.discountVisible;
    mappedData.image =
        typeof requestData.discountImage === 'string'
            ? null
            : requestData.discountImage;
    mappedData.imageAltText = requestData.discountImageAltText;

    requestData.discountLocations.forEach(dl => {
        mappedData.locations = [
            ...(mappedData?.locations ?? []),
            {
                name: dl.name,
                isNew: dl?.isNew ?? false,
                id: dl?.id ?? null
            }
        ];
    });

    if (requestData.discountMaximum) {
        mappedData.maximum = requestData.discountMaximum;
    }

    if (requestData.discountCategory.value) {
        mappedData.categoryId = requestData.discountCategory.value;
    }

    if (
        filesForDeletion.filter(fileId => Number.isInteger(fileId)).length >
            0 &&
        isEditMode
    ) {
        mappedData.filesSetForDeletion = filesForDeletion;
    }

    const formData = getFormData(mappedData, '', null);

    requestData.discountFiles
        .filter(file => typeof file !== 'string')
        .forEach(file => {
            formData.append('files', file);
        });

    isEditMode
        ? dispatch(updateDiscount(editModalDiscountId, formData))
        : dispatch(createDiscount(formData));

    setIsDiscountModalOpen(false);
};

export const setEditModalState = (
    discountData,
    setSelectedDiscountLocations,
    setFilesToBeUploaded,
    setDiscountCategorySearchValue,
    setDiscountSearchedCategory,
    setFilesForDeletion
) => {
    setSelectedDiscountLocations(
        getSelectedLocationOptions(discountData.discountLocations)
    );
    setFilesToBeUploaded(discountData.discountFiles);

    setDiscountCategorySearchValue(discountData.discountCategoryName);
    setDiscountSearchedCategory({
        label: discountData.discountCategoryName,
        value: discountData.discountCategoryId
    });
    setFilesForDeletion([]);
};
