import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    createBenefitType,
    fetchBenefits,
    resetBenefitTypeState,
    resetFilters,
    changeOrder,
    resetShowCreateMessage,
    changeActiveState,
    updateSearchPhrase
} from 'redux/slices/benefitType';
import { fetchCoveredByDropDown } from 'redux/slices/dropdown';
import { CustomButton } from 'shared/components/Button';
import { successNotification } from 'shared/components/Notifications';
import { getFormData } from 'utils/getFormData';

import { StyledBenefitsTab } from './styled/BenefitsTab.styled';
import { BenefitTypesTable } from './Table/BenefitTypesTable';
import SubsetTabSearch from '../../../people/components/Dashboard/PeopleSubsetTab/SubsetTabSearch';
import Footer from '../../../shared/components/layout/Footer';
import useInfiniteScroll from '../../../shared/hooks/useInfiniteScroll';
import { BenefitTypeModal } from '../shared/BenefitTypeModal/BenefitTypeModal';

export const BenefitsTab = ({ handleCreateColumns, permissions }) => {
    const dispatch = useDispatch();
    const {
        currentBenefits,
        currentTotal,
        currentBenefitsTotalCount,
        isLoading,
        showCreateMessage,
        loadActive,
        searchPhrase,
        sortBy,
        sortAsc
    } = useSelector(state => state.benefitType);

    const [sortState, setSortState] = useState([{ id: 'name', desc: false }]);
    const [addBenefitModal, setAddBenefitModal] = useState(false);

    useEffect(() => {
        if (showCreateMessage) {
            successNotification('Successfully created benefit.');
            dispatch(resetShowCreateMessage());
        }
    }, [showCreateMessage]);

    useEffect(() => {
        dispatch(fetchBenefits(false));
    }, [searchPhrase, sortBy, sortAsc, loadActive]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, []);

    useEffect(() => {
        dispatch(fetchCoveredByDropDown());
    }, []);

    const handleSortUpdate = value => {
        setSortState(value);
        dispatch(changeOrder(value));
        dispatch(resetBenefitTypeState());
    };

    // infinite scroll handling
    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            dispatch(fetchBenefits(true));
        },
        isLoading,
        hasMore: Boolean(currentBenefits.length < currentBenefitsTotalCount)
    });

    const handleOpenAddModal = () => {
        setAddBenefitModal(true);
    };

    const handleSubmitCreate = values => {
        const benefitPrice = values.benefitPrices[0];
        const formData = getFormData(
            {
                ...values,
                benefitPrice: benefitPrice.benefitPrice,
                benefitPriceGroupId: benefitPrice.benefitPriceGroupId,
                startDate: moment(benefitPrice.startDate).format('YYYY-MM-DD'),
                endDate: isEmpty(benefitPrice.endDate)
                    ? null
                    : moment(benefitPrice.endDate).format('YYYY-MM-DD')
            },
            null,
            ['benefitPrices']
        );

        dispatch(createBenefitType(formData));
        setAddBenefitModal(false);
    };

    const handleValueUpdate = (_, val) => {
        dispatch(updateSearchPhrase(val));
    };

    const getClass = active => {
        return `inactive-benefits-button${active ? '-active' : ''} btn-normal`;
    };

    return (
        <>
            <StyledBenefitsTab>
                <div className="all-benefits-container">
                    <div className="benefit-extra-header">
                        <SubsetTabSearch
                            search={{ handleValueUpdate }}
                            allowLettersOnly={false}
                        />
                        <div className="active-button-container">
                            <button
                                data-testid="active-show-button"
                                className={getClass(loadActive)}
                                onClick={() =>
                                    dispatch(changeActiveState(true))
                                }
                            >
                                Active
                            </button>
                            <button
                                data-testid="inactive-show-button"
                                className={getClass(!loadActive)}
                                onClick={() =>
                                    dispatch(changeActiveState(false))
                                }
                            >
                                Inactive
                            </button>
                        </div>
                        <CustomButton
                            type={'normal'}
                            text={'Add New Flexible Benefit'}
                            onClick={handleOpenAddModal}
                            disabled={!permissions.canCreateNewBenefitType}
                        />
                    </div>
                    <BenefitTypesTable
                        totalCount={currentTotal}
                        defaultSort={sortState}
                        handleCreateTableColumns={handleCreateColumns}
                        pageSize={10}
                        loading={isLoading}
                        noDataText={'No Benefit Types Found'}
                        hasPagination={false}
                        sorted={sortState}
                        handleSortUpdate={handleSortUpdate}
                        boundaryRef={boundaryRef}
                        permissions={permissions}
                    />
                    {addBenefitModal && (
                        <BenefitTypeModal
                            title={'Add Flexible Benefit'}
                            visible={addBenefitModal}
                            isEditModal={false}
                            setVisible={setAddBenefitModal}
                            handleSubmit={handleSubmitCreate}
                        />
                    )}
                </div>
            </StyledBenefitsTab>
            {!Boolean(currentBenefits.length < currentBenefitsTotalCount) && (
                <Footer className={'special'} />
            )}
        </>
    );
};
