import { getFormData } from 'utils/getFormData';

import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getAllOffers = async (requestModel, active) => {
    const { skip, take, sortBy, sortAsc, searchPhrase } = requestModel;
    const res = await httpService.get(url.GET_ALL_OFFERS_PAGINATED(), {
        params: {
            skip,
            take,
            sortBy,
            sortAsc,
            query: searchPhrase,
            isActive: active
        }
    });

    return res.data;
};

const getOfferById = async offerId => {
    const res = await httpService.get(url.GET_OFFER_BY_ID(offerId));

    return res.data;
};

const addOffer = async requestData => {
    const formData = new FormData();
    requestData.files.forEach(file => {
        if (file.isDeleted) return;
        formData.append('files', file.data);
    });
    formData.append('name', requestData.name);
    formData.append('shortDescription', requestData.shortDescription);
    formData.append('howMuch', requestData.howMuch);
    formData.append('website', requestData.website ?? '');
    formData.append('selectable', requestData.selectable);
    formData.append('howToUse', requestData.howToUse);
    formData.append('active', requestData.active);
    formData.append('image', requestData.image);
    formData.append('imageAltText', requestData.imageAltText ?? '');

    const locations = requestData.locations.map(ol => {
        return {
            name: ol.description,
            isNew: ol?.isNew ?? false,
            id: ol?.id ?? null
        };
    });

    getFormData(locations, 'locations', null, formData);

    const data = await httpService.post(url.CREATE_OFFER(), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return data.data;
};

const updateOffer = async (offerId, requestData) => {
    const formData = new FormData();
    requestData.files.forEach(file => {
        if (file.isDeleted) {
            if (file.isNew) return;
            formData.append('deletedFiles', file.uid);
        }

        if (file.isNew) {
            if (file.isDeleted) return;
            formData.append('files', file.data);
        }
    });
    formData.append('name', requestData.name);
    formData.append('shortDescription', requestData.shortDescription);
    formData.append('howMuch', requestData.howMuch);
    formData.append('website', requestData.website ?? '');
    formData.append('selectable', requestData.selectable);
    formData.append('howToUse', requestData.howToUse);
    formData.append('active', requestData.active);
    formData.append('imageAltText', requestData.imageAltText ?? '');

    if (requestData.updateImage) {
        formData.append('image', requestData.image);
        formData.append('updateImage', requestData.updateImage);
    } else {
        formData.append('image', null);
    }

    const locations = requestData.locations.map(ol => {
        return {
            name: ol.description,
            isNew: ol?.isNew ?? false,
            id: ol?.id ?? null
        };
    });

    getFormData(locations, 'locations', null, formData);

    const data = await httpService.patch(url.UPDATE_OFFER(offerId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return data.data;
};

const updateOfferVisibility = async offerId => {
    const data = await httpService.patch(url.UPDATE_OFFER_VISIBILITY(offerId));
    return data.data;
};

const deleteOffer = async offerId => {
    const data = await httpService.delete(url.DELETE_OFFER(offerId));
    return data.data;
};

const getAvailableLocationOptions = async () => {
    const data = await httpService.get(url.GET_OFFER_LOCATIONS());
    return data.data;
};

export default {
    getAllOffers,
    getOfferById,
    addOffer,
    updateOffer,
    deleteOffer,
    updateOfferVisibility,
    getAvailableLocationOptions
};
