import React, { useEffect } from 'react';

import { BenefitTypeModalAdd } from './BenefitTypeModalAdd';
import { BenefitTypeModalUpdate } from './BenefitTypeModalUpdate';
import StyledBenefitPriceWrapper from '../../shared/BenefitTypeModal/styled/BenefitPriceWrapper.styled';

const BenefitPriceWrapper = ({
    isEditModal,
    benefitPrices,
    setFieldValue,
    initialValues,
    benefitPriceErrors,
    setBenefitPriceErrors
}) => {
    useEffect(() => {
        setBenefitPriceErrors(new Array(benefitPrices.length).fill({}));
    }, []);

    return (
        <StyledBenefitPriceWrapper>
            {isEditModal ? (
                <BenefitTypeModalUpdate
                    initialValues={initialValues}
                    benefitPrices={benefitPrices}
                    setFieldValue={setFieldValue}
                    benefitPriceErrors={benefitPriceErrors}
                    setBenefitPriceErrors={setBenefitPriceErrors}
                />
            ) : (
                <BenefitTypeModalAdd
                    benefitPrices={benefitPrices}
                    setFieldValue={setFieldValue}
                    benefitPriceErrors={benefitPriceErrors}
                    setBenefitPriceErrors={setBenefitPriceErrors}
                />
            )}
        </StyledBenefitPriceWrapper>
    );
};

export { BenefitPriceWrapper };
