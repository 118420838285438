import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    fetchSingleOffer,
    setAddEditOfferModalOpen,
    updateOfferVisibility,
    deleteOffer,
    resetNotifications
} from 'redux/slices/offer';
import { CustomConfirmationModal } from 'shared/components';
import { successNotification } from 'shared/components/Notifications';

import { CustomTableWithFixedColumns } from '../../../../shared/components/CustomTableWithFixedColumns';
import { OfferModal } from '../../shared/OfferModal/OfferModal';

export const OffersTable = ({
    totalCount,
    defaultSort,
    handleCreateTableColumns,
    offerData,
    pageSize,
    loading,
    noDataText,
    hasPagination,
    sorted,
    handleSortUpdate,
    boundaryRef,
    permissions
}) => {
    const dispatch = useDispatch();
    const { isAddEditModalOpen, isEditMode, isAddedEditedSuccessfully } =
        useSelector(state => state.offer);
    const [isVisibilityModalOpen, setIsVisibilityModalOpen] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [deleteConfirmationModal, setDeleteConfirmationModal] =
        useState(false);

    useEffect(() => {
        if (isAddedEditedSuccessfully && isEditMode) {
            successNotification('Successfully updated Offer.');
        } else if (isAddedEditedSuccessfully && !isEditMode) {
            successNotification('Successfully added an Offer.');
        }

        dispatch(resetNotifications());
    }, [isAddedEditedSuccessfully]);

    const closeModalCallback = () => {
        setSelectedOffer(null);
    };

    const handleOpenEdit = offer => {
        setSelectedOffer(offer);
        dispatch(setAddEditOfferModalOpen(true, false));
        dispatch(fetchSingleOffer(offer.offerId));
    };

    const handleToggleVisibility = offer => {
        setSelectedOffer(offer);
        setIsVisibilityModalOpen(true);
    };

    const handleVisibilityModalCancel = () => {
        setSelectedOffer(null);
        setIsVisibilityModalOpen(false);
    };

    const handleToggleVisibilityConfirm = () => {
        dispatch(updateOfferVisibility(selectedOffer.offerId));
        setSelectedOffer(null);
        setIsVisibilityModalOpen(false);
    };

    const handleOpenDelete = offer => {
        setDeleteConfirmationModal(true);
        setSelectedOffer(offer);
    };

    const handleDelete = () => {
        dispatch(deleteOffer(selectedOffer.offerId));
        setSelectedOffer(null);
        setDeleteConfirmationModal(false);
    };

    const handleEditOffer = requestData => {};

    return (
        <div className="table-wrapper">
            <CustomTableWithFixedColumns
                total={totalCount}
                defaultSorted={defaultSort}
                columns={handleCreateTableColumns(
                    offerData,
                    handleOpenEdit,
                    handleToggleVisibility,
                    handleOpenDelete,
                    permissions
                )}
                data={offerData}
                pageSize={pageSize}
                loading={loading}
                noDataText={noDataText}
                hasPagination={hasPagination}
                sorted={sorted}
                handleSortingChange={handleSortUpdate}
                sortable={true}
                resizable={false}
            />
            <section ref={boundaryRef} />

            {isVisibilityModalOpen && (
                <CustomConfirmationModal
                    visible={isVisibilityModalOpen}
                    setVisible={setIsVisibilityModalOpen}
                    closeCallback={handleVisibilityModalCancel}
                    handleConfirm={handleToggleVisibilityConfirm}
                    secondaryTitle={
                        'Are you sure you want to show/hide the offer?'
                    }
                />
            )}
            {deleteConfirmationModal && (
                <CustomConfirmationModal
                    title={'Confirmation'}
                    visible={deleteConfirmationModal}
                    secondaryTitle={''}
                    setVisible={setDeleteConfirmationModal}
                    contentText={`Are you sure you want to remove "${selectedOffer.name}"  from the system?`}
                    handleConfirm={handleDelete}
                    closeCallback={closeModalCallback}
                />
            )}
        </div>
    );
};
