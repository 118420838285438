import React, { useEffect } from 'react';

import dropdownService from 'people/services/dropdownService';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMainInformationDropDowns } from 'redux/slices/dropdown';
import { AsyncSearchSelect } from 'shared/components/AsyncSearchSelect';
import { CustomDateSelector } from 'shared/components/DatePicker';
import { FieldsSection } from 'shared/components/FieldsSection';
import { CustomInput } from 'shared/components/Input';
import { errorNotification } from 'shared/components/Notifications';
import { CustomSelect } from 'shared/components/Select';
import {
    selectPlaceholder,
    setAllowClear,
    setClassName
} from 'utils/componentPropHelpers';
import { mapDataForDropdown } from 'utils/dropdownHelper';
import { checkTokenExp, redirectTokenExp } from 'utils/tokenHelper';

import { WORKING_TIMES } from '../../../shared/constants/workingTimes';

const PersonModalExtension = ({ values, errors, setFieldValue = () => {} }) => {
    const dispatch = useDispatch();
    const {
        typesOfMentormater,
        locations,
        levels,
        departments,
        industries,
        typesOfWork,
        hiringChannels,
        positions
    } = useSelector(state => state.dropdown);

    useEffect(() => {
        if (
            typesOfMentormater.length === 0 ||
            locations.length === 0 ||
            levels.length === 0 ||
            departments.length === 0 ||
            industries.length === 0 ||
            typesOfWork.length === 0 ||
            hiringChannels.length === 0 ||
            positions.length === 0
        ) {
            dispatch(fetchMainInformationDropDowns());
        }
    }, []);

    const typesOfMentormaterOptions = mapDataForDropdown(typesOfMentormater);
    const locationsOptions = mapDataForDropdown(locations);
    const levelsOptions = mapDataForDropdown(levels);
    const departmentsOptions = mapDataForDropdown(
        departments.filter(x => x.active)
    );
    const typesOfWorkOptions = mapDataForDropdown(typesOfWork);
    const industriesOptions = mapDataForDropdown(industries);
    const hiringChannelsOptions = mapDataForDropdown(hiringChannels);
    const positionsOptions = mapDataForDropdown(positions);

    const fetchPersonData = async (filter, previousId = null) => {
        try {
            if (checkTokenExp()) {
                redirectTokenExp();
            }
            const resp = await dropdownService.getReportsTo(filter, previousId);
            const { data } = resp;
            return data;
        } catch (e) {
            const msg = e.message;
            errorNotification(msg);
        }
    };

    return (
        <div className="modal-body-section">
            <div className="section-title">Working Period</div>
            <FieldsSection>
                <div className="section-inputs">
                    <CustomDateSelector
                        className={setClassName(errors?.startDate)}
                        value={values.startDate}
                        label="First Date *"
                        name="startDate"
                        onChange={setFieldValue}
                    />
                    <CustomSelect
                        value={values.mentormaterId}
                        label="Type of MentorMater"
                        name="mentormaterId"
                        placeholder={selectPlaceholder}
                        allowClear={setAllowClear(values.mentormaterId)}
                        options={typesOfMentormaterOptions}
                        onChange={setFieldValue}
                    />
                    <CustomInput
                        className={setClassName(errors?.trialPeriod)}
                        value={values.trialPeriod}
                        label="Trial Period"
                        name="trialPeriod"
                        placeholder="Type..."
                        allowClear={true}
                        isNormalChange={false}
                        onChange={(name, val) => {
                            setFieldValue(name, val);
                        }}
                    />
                    <CustomSelect
                        value={values.workingTime}
                        label="Working Time *"
                        name="workingTime"
                        placeholder="Working Time"
                        options={WORKING_TIMES}
                        onChange={setFieldValue}
                    />
                    <CustomSelect
                        value={values.locationId}
                        label="Dedicated Office"
                        name="locationId"
                        placeholder={selectPlaceholder}
                        allowClear={setAllowClear(values.locationId)}
                        options={locationsOptions}
                        onChange={setFieldValue}
                    />
                    <CustomInput
                        className={setClassName(errors?.workLocation)}
                        value={values.workLocation}
                        label="Work Location"
                        name="workLocation"
                        placeholder="Type..."
                        allowClear={true}
                        isNormalChange={false}
                        onChange={(name, val) => {
                            setFieldValue(name, val);
                        }}
                    />
                </div>
            </FieldsSection>
            <div className="section-splitter"></div>
            <FieldsSection>
                <div className="section-inputs">
                    <CustomSelect
                        label="Position"
                        allowClear={true}
                        placeholder="Type to autocomplete..."
                        options={positionsOptions}
                        value={values.positionTitle}
                        name={'positionTitle'}
                        onChange={(name, val) => {
                            const selectedPosition = positionsOptions.find(
                                p => p.value === val
                            );
                            setFieldValue(name, selectedPosition.label);
                        }}
                        className={setClassName(errors?.positionTitle)}
                        hasInput={true}
                    />
                    <AsyncSearchSelect
                        label="Reports to"
                        value={values.reportsToId}
                        name={'reportsToId'}
                        displayDefaultOption={false}
                        setValueOption={setFieldValue}
                        placeholder={selectPlaceholder}
                        getData={fetchPersonData}
                        allowClear={true}
                        initialCallProps={['', [values.reportsToId]]}
                    />
                    <CustomSelect
                        value={values.levelId}
                        label="Level"
                        name="levelId"
                        placeholder={selectPlaceholder}
                        allowClear={setAllowClear(values.levelId)}
                        options={levelsOptions}
                        onChange={setFieldValue}
                    />
                    <CustomSelect
                        value={values.departmentId}
                        label="Department"
                        name="departmentId"
                        placeholder={selectPlaceholder}
                        allowClear={setAllowClear(values.departmentId)}
                        options={departmentsOptions}
                        onChange={setFieldValue}
                    />
                    <CustomSelect
                        value={values.industryId}
                        label="Industry"
                        name="industryId"
                        placeholder={selectPlaceholder}
                        allowClear={setAllowClear(values.industryId)}
                        options={industriesOptions}
                        onChange={setFieldValue}
                    />
                    <CustomSelect
                        value={values.typeOfWorkId}
                        label="Type of Work"
                        name="typeOfWorkId"
                        placeholder={selectPlaceholder}
                        allowClear={setAllowClear(values.typeOfWorkId)}
                        options={typesOfWorkOptions}
                        onChange={setFieldValue}
                    />
                </div>
            </FieldsSection>
            <div className="section-splitter"></div>
            <FieldsSection>
                <div className="section-inputs">
                    <CustomSelect
                        value={values.hiringChannelId}
                        label="Hiring Channel"
                        name="hiringChannelId"
                        placeholder={selectPlaceholder}
                        allowClear={setAllowClear(values.hiringChannelId)}
                        options={hiringChannelsOptions}
                        onChange={setFieldValue}
                    />
                    <CustomInput
                        className={setClassName(errors?.hiringChannelText)}
                        value={values.hiringChannelText}
                        name="hiringChannelText"
                        placeholder="Type..."
                        noLabelStyle={true}
                        allowClear={true}
                        isNormalChange={false}
                        onChange={(name, val) => {
                            setFieldValue(name, val);
                        }}
                    />
                </div>
            </FieldsSection>
        </div>
    );
};

export { PersonModalExtension };
