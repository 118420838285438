import React, { useEffect } from 'react';

import { Checkbox, Switch } from 'antd';
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchBeneficiariesDropDown,
    fetchBenefitReportsDropDowns
} from 'redux/slices';
import { CustomSelect } from 'shared/components';
import { CustomInput } from 'shared/components/Input';
import RichText from 'shared/components/RichText';
import Column from 'shared/components/styled/Column.styled';
import Row from 'shared/components/styled/Row.styled';
import { UploadImage } from 'shared/components/UploadImage';
import { setClassName } from 'utils/componentPropHelpers';
import { mapDataForDropdown } from 'utils/dropdownHelper';
import { filterObject } from 'utils/filterObject';

import { BenefitPriceWrapper } from './BenefitPriceWrapper';
import StyledBenefitPriceSection from './styled/BenefitPriceSection.styled';
import { RequestPropertyCollection } from '../../../../benefits/constants/RequestPropertyCollection';
import {
    MAX_LENGTH_RICH_TEXT,
    validateBenefitTypeForm,
    validateShort,
    validateBenefitTypeImageAltText,
    validateBenefitContentfulId
} from '../../../utils/shared/benefitModalHelper';

const BenefitDetailsTab = ({
    handleRichTextChange,
    isEditModal,
    formikValues,
    values,
    setFieldValue,
    setBenefitPriceErrors,
    benefitPriceErrors,
    errors,
    setErrors
}) => {
    const dispatch = useDispatch();
    const { beneficiaries, benefitReportsTypes } = useSelector(x => x.dropdown);

    const coveredByOptions = useSelector(
        state => state.dropdown.benefitCoveredBy
    );

    useEffect(() => {
        dispatch(fetchBeneficiariesDropDown());
        dispatch(fetchBenefitReportsDropDowns());
    }, [dispatch]);

    const validateName = (name, val) => {
        const currentData = {
            [name]: val
        };

        const updatedErrors = filterObject(
            {
                ...errors,
                ...validateBenefitTypeForm(currentData)
            },
            value => value
        );

        setErrors(updatedErrors);
    };

    const validateShortDescription = (name, val) => {
        const currentData = {
            [name]: val
        };

        const updatedErrors = filterObject(
            {
                ...errors,
                ...validateShort(currentData)
            },
            value => value
        );

        setErrors(updatedErrors);
    };

    return (
        <Column height="100%">
            <UploadImage
                onUpload={file => {
                    setFieldValue('benefitTypeImage', file);
                    setFieldValue('benefitTypeImageAltText', '');
                    setErrors(prev => {
                        delete prev.benefitTypeImage;
                        return prev;
                    });
                }}
                imageText="Select Image *"
                initialImage={values.benefitTypeImage}
                maxSize={MAX_IMAGE_SIZE}
                externalError={setClassName(errors?.benefitTypeImageAltText)}
                hideDeleteButton={true}
            />

            <CustomInput
                label="Image Alt Text *"
                placeholder="Image Alt Text..."
                value={values.benefitTypeImageAltText}
                name="benefitTypeImageAltText"
                className={setClassName(errors?.benefitTypeImageAltText)}
                isNormalChange={false}
                onChange={(name, value) => {
                    setFieldValue(name, value);
                    validateBenefitTypeImageAltText(setErrors, value);
                }}
                allowClear={true}
            />

            <CustomInput
                label="Flexible Benefit Name *"
                placeholder="Type..."
                value={values.benefitTypeName}
                name={'benefitTypeName'}
                className={setClassName(errors?.benefitTypeName)}
                isNormalChange={false}
                onChange={(name, val) => {
                    setFieldValue(name, val);
                    validateName(name, val);
                }}
                allowClear={true}
            />

            <CustomInput
                label="Short Flexible Benefit Description *"
                placeholder="short description..."
                value={values.benefitTypeShortDescription}
                name={'benefitTypeShortDescription'}
                className={setClassName(errors?.benefitTypeShortDescription)}
                isNormalChange={false}
                onChange={(name, val) => {
                    setFieldValue(name, val);
                    validateShortDescription(name, val);
                }}
                allowClear={true}
            />

            <RichText
                label="Description"
                placeholder="Benefit Description ..."
                value={values.benefitTypeDescription.html}
                name={'benefitTypeDescription'}
                hasError={errors?.benefitTypeDescription}
                onChange={(name, values) =>
                    handleRichTextChange(name, values, setFieldValue)
                }
                maxLength={MAX_LENGTH_RICH_TEXT}
                id={'benefitTypeDescription'}
            />
            <BenefitPriceWrapper
                isEditModal={isEditModal}
                initialValues={formikValues?.benefitPrices}
                benefitPrices={values.benefitPrices}
                setFieldValue={setFieldValue}
                benefitPriceErrors={benefitPriceErrors}
                setBenefitPriceErrors={setBenefitPriceErrors}
            />
            <StyledBenefitPriceSection>
                <Row>
                    <Column width={'100%'}>
                        <CustomSelect
                            label={'Beneficiary'}
                            name={'benefitBeneficiaryId'}
                            placeholder={'Select a beneficiary'}
                            value={values.benefitBeneficiaryId}
                            onChange={(name, val) => {
                                setFieldValue(name, val);

                                if (!val) {
                                    setFieldValue(
                                        'benefitRequestProperty',
                                        null
                                    );
                                }
                            }}
                            options={mapDataForDropdown(beneficiaries)}
                            allowClear={true}
                        />
                    </Column>
                    <Column width={'100%'}>
                        <CustomSelect
                            label={'Request Property'}
                            name={'benefitRequestProperty'}
                            placeholder={'Select request property'}
                            value={values.benefitRequestProperty}
                            onChange={(name, val) => {
                                setFieldValue(name, val);
                            }}
                            disabled={!values.benefitBeneficiaryId}
                            options={RequestPropertyCollection}
                            allowClear={true}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column width={'100%'}>
                        <CustomSelect
                            label="Report Group"
                            name={'benefitGroupId'}
                            value={values.benefitGroupId} //TODO
                            placeholder={'Type or select benefit'}
                            onChange={(name, val) => {
                                setFieldValue(name, val);
                            }}
                            options={mapDataForDropdown(benefitReportsTypes)}
                            allowClear
                        />
                    </Column>

                    <Column width={'100%'}>
                        <CustomInput
                            label="Contentful ID"
                            placeholder={'Type Contentful ID'}
                            name={'benefitContentfulId'}
                            value={values.benefitContentfulId}
                            className={setClassName(
                                errors?.benefitContentfulId
                            )}
                            isNormalChange={false}
                            onChange={(name, val) => {
                                setFieldValue(name, val);
                                validateBenefitContentfulId(setErrors, val);
                            }}
                            allowClear={true}
                        />
                    </Column>
                </Row>
            </StyledBenefitPriceSection>
            <Column gap="5px">
                <span id="coveredBy" className="input-label">
                    Covered By
                </span>
                <div
                    role="group"
                    aria-labelledby="coveredBy"
                    className="checkbox-group"
                >
                    {coveredByOptions.map(option => (
                        <div className="checkbox" key={option.id}>
                            <Field
                                type="checkbox"
                                name="benefitTypeCoveredBy"
                                value={option.id.toString()}
                                as={Checkbox}
                                id={option.description}
                            />
                            <label htmlFor={option.description}>
                                {option.description}
                            </label>
                        </div>
                    ))}
                </div>
            </Column>

            <Row justifyContent="space-between">
                <span className="input-label">
                    Make this Flexible Benefit visible on MentorNet
                </span>
                <Row>
                    <Switch
                        checked={values.benefitTypeSelectable}
                        checkedChildren="YES"
                        unCheckedChildren="NO"
                        onChange={checked => {
                            setFieldValue('benefitTypeSelectable', checked);
                        }}
                    />
                </Row>
            </Row>
        </Column>
    );
};

export default BenefitDetailsTab;

const MAX_IMAGE_SIZE = 5242880; // 1024*1024*5 -> 5MB
