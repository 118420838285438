import React, { useEffect, useState } from 'react';

import { Tabs } from 'antd';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { resetBenefitType } from 'redux/slices/benefitType';
import { CustomButton } from 'shared/components/Button';
import { CloseButton } from 'shared/components/CloseButton';
import { errorNotification } from 'shared/components/Notifications';
import { Spinner } from 'shared/components/Spinner';
import { filterObject } from 'utils/filterObject';

import BenefitDetailsTab from './BenefitDetailsTab';
import ContactsTab from './ContactsTab';
import HowToUseTab from './HowToUseTab';
import OverviewTab from './OverviewTab';
import { StyledBenefitTypeModal } from './styled/BenefitTypeModal.styled';
import {
    benefitPricesValid,
    disableBenefitType,
    validateBenefitTypeOnSubmit,
    validateRichTextLength
} from '../../../utils/shared/benefitModalHelper';

const BenefitTypeModal = ({
    title,
    initialValue = INITIAL_VALUES,
    visible,
    isEditModal,
    setVisible = () => {},
    handleSubmit = () => {},
    closeCallback = () => {}
}) => {
    const dispatch = useDispatch();
    const { isLoadingFetchSingle, benefitTypeSingleData, errorFetchSingle } =
        useSelector(state => state.benefitType);

    const coveredByOptions = useSelector(
        state => state.dropdown.benefitCoveredBy
    );
    const [errors, setErrors] = useState({});
    const [benefitPriceErrors, setBenefitPriceErrors] = useState([]);
    const [defaultActiveKey, setDefaultActiveKey] = useState(1);

    useEffect(() => {
        if (errorFetchSingle) {
            errorNotification(errorFetchSingle);
        }
    }, [errorFetchSingle]);

    useEffect(() => {
        return () => {
            if (isEditModal) {
                dispatch(resetBenefitType());
            }
        };
    }, []);

    const handleClose = () => {
        closeCallback();
        setVisible(false);
    };

    const handleRichTextChange = (name, values, setFieldValue) => {
        setFieldValue(name, {
            html: values.html,
            plainText: values.plainText
        });

        const updatedErrors = filterObject(
            {
                ...errors,
                ...validateRichTextLength(name, values.plainText)
            },
            value => value
        );

        setErrors(updatedErrors);
    };

    const localSubmit = submitValues => {
        let errorsIncome = filterObject(
            {
                ...errors,
                ...validateBenefitTypeOnSubmit(submitValues)
            },
            value => value
        );

        setErrors(errorsIncome);

        const isCorrectBenefitType = !disableBenefitType(
            submitValues,
            errorsIncome
        );
        const { submitErrors, allowSubmit } = benefitPricesValid(
            submitValues.benefitPrices,
            benefitPriceErrors
        );
        setBenefitPriceErrors([...submitErrors]);

        if (!isCorrectBenefitType || !allowSubmit) {
            setDefaultActiveKey(1);
            return;
        }

        handleSubmit({
            ...submitValues,
            benefitTypeCoveredBy: coveredByOptions.map(option => ({
                coveredById: option.id,
                isSelected: submitValues.benefitTypeCoveredBy.includes(
                    option.id.toString()
                )
            })),
            benefitTypeDescription: submitValues.benefitTypeDescription.html,
            benefitTypeOverview: submitValues.benefitTypeOverview.html,
            benefitTypeHowToUse: submitValues.benefitTypeHowToUse.html,
            benefitTypeContacts: submitValues.benefitTypeContacts.html
        });
    };

    const editInitialValues = benefitTypeSingleData && {
        ...benefitTypeSingleData,
        benefitTypeCoveredBy: benefitTypeSingleData?.benefitTypeCoveredBy
            .map(benefitTypeCoveredBy =>
                benefitTypeCoveredBy.isSelected
                    ? benefitTypeCoveredBy.coveredById.toString()
                    : null
            )
            .filter(Boolean),
        benefitTypeDescription: {
            html: benefitTypeSingleData.benefitTypeDescription
        },
        benefitTypeOverview: {
            html: benefitTypeSingleData.benefitTypeOverview
        },
        benefitTypeHowToUse: {
            html: benefitTypeSingleData.benefitTypeHowToUse
        },
        benefitTypeContacts: {
            html: benefitTypeSingleData.benefitTypeContacts
        }
    };

    const editBenefitPrices =
        editInitialValues && editInitialValues.benefitPrices.length !== 0
            ? editInitialValues.benefitPrices
            : initialValue.benefitPrices;

    const formikValues =
        isEditModal && editInitialValues
            ? { ...editInitialValues, benefitPrices: editBenefitPrices }
            : initialValue;

    return (
        <Formik initialValues={formikValues} enableReinitialize={true}>
            {props => {
                const { values, setFieldValue } = props;
                return (
                    <StyledBenefitTypeModal
                        visible={visible}
                        setVisible={setVisible}
                        title={[
                            <div
                                className="custom-modal-header"
                                key="modal-title"
                            >
                                {title}
                                <CloseButton handleClose={handleClose} />
                            </div>
                        ]}
                        footer={
                            !isLoadingFetchSingle && (
                                <div
                                    className="custom-modal-footer"
                                    key="modal-footer"
                                >
                                    <div className="button-section">
                                        <CustomButton
                                            text="Close"
                                            type="normal footer-button"
                                            onClick={handleClose}
                                            disabled={isLoadingFetchSingle}
                                        />
                                    </div>
                                    <div className="button-section">
                                        <CustomButton
                                            text="Save"
                                            type="filled footer-button"
                                            onClick={() => localSubmit(values)}
                                            disabled={
                                                isLoadingFetchSingle ||
                                                !isEmpty(errors) ||
                                                !benefitPriceErrors.every(
                                                    errors => isEmpty(errors)
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            )
                        }
                        handleCustomCancel={closeCallback}
                        data-testid="benefit-type-modal"
                    >
                        {isLoadingFetchSingle ||
                        values.id === 0 ||
                        errorFetchSingle ? (
                            <div className="spinner-container">
                                <Spinner />
                            </div>
                        ) : (
                            <Form>
                                <Tabs
                                    tabPosition="top"
                                    animated={{ inkBar: false, tabPane: false }}
                                    activeKey={defaultActiveKey}
                                    onChange={activeKey =>
                                        setDefaultActiveKey(activeKey)
                                    }
                                    items={[
                                        {
                                            label: 'Flexible Benefit Details',
                                            key: 1,
                                            children: (
                                                <BenefitDetailsTab
                                                    values={values}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    isEditModal={isEditModal}
                                                    formikValues={formikValues}
                                                    handleRichTextChange={
                                                        handleRichTextChange
                                                    }
                                                    errors={errors}
                                                    setErrors={setErrors}
                                                    benefitPriceErrors={
                                                        benefitPriceErrors
                                                    }
                                                    setBenefitPriceErrors={
                                                        setBenefitPriceErrors
                                                    }
                                                />
                                            )
                                        },
                                        {
                                            label: 'Overview',
                                            key: 2,
                                            children: (
                                                <OverviewTab
                                                    handleRichTextChange={
                                                        handleRichTextChange
                                                    }
                                                    values={values}
                                                    errors={errors}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                />
                                            )
                                        },
                                        {
                                            label: 'How to Use',
                                            key: 3,
                                            children: (
                                                <HowToUseTab
                                                    values={values}
                                                    handleRichTextChange={
                                                        handleRichTextChange
                                                    }
                                                    errors={errors}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                />
                                            )
                                        },
                                        {
                                            label: 'Contacts',
                                            key: 4,
                                            children: (
                                                <ContactsTab
                                                    values={values}
                                                    handleRichTextChange={
                                                        handleRichTextChange
                                                    }
                                                    errors={errors}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                />
                                            )
                                        }
                                    ]}
                                />
                            </Form>
                        )}
                    </StyledBenefitTypeModal>
                );
            }}
        </Formik>
    );
};

export { BenefitTypeModal };

const INITIAL_VALUES = {
    benefitTypeName: '',
    benefitTypeShortDescription: '',
    benefitTypeCoveredBy: [],
    benefitPrices: [
        {
            id: 0,
            benefitPrice: '',
            startDate: new Date(),
            endDate: undefined,
            active: true
        }
    ],
    benefitTypeSelectable: false,
    benefitTypeImage: null,
    benefitTypeImageAltText: '',
    benefitTypeImageName: '',
    benefitTypeDescription: { html: '', plainText: '' },
    benefitTypeOverview: { html: '', plainText: '' },
    benefitTypeHowToUse: { html: '', plainText: '' },
    benefitTypeContacts: { html: '', plainText: '' },
    benefitBeneficiaryId: null,
    benefitGroupId: null,
    benefitRequestProperty: null,
    benefitContentfulId: null
};
