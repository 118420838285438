import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import {
    canDeleteWorkingPeriod,
    canEditWorkingPeriod,
    getDisabledClassNameDeleteWorkingPeriod
} from 'people/utils/peoplePermissionsHelper';
import { useDispatch, useSelector } from 'react-redux';
import { TakesEffectModal } from 'shared/components/TakesEffectModal';

import OnLeaveFormSection from './OnLeaveFormSection';
import { StyledWorkingPeriodBody } from './styled/WorkingPeriodBody.styled';
import {
    removeWorkingPeriod,
    resetWorkingPeriodLoadingStack,
    updateWorkingPeriod,
    createEndDateWorkingPeriod,
    deleteEndDateWorkingPeriod,
    fetchWorkingPeriods
} from '../../../../redux/slices/workingPeriod';
import { AsyncSearchSelect } from '../../../../shared/components/AsyncSearchSelect';
import { CustomConfirmationModal } from '../../../../shared/components/ConfirmationModal';
import { CustomDateSelector } from '../../../../shared/components/DatePicker';
import { FieldsSection } from '../../../../shared/components/FieldsSection';
import { CustomInput } from '../../../../shared/components/Input';
import { errorNotification } from '../../../../shared/components/Notifications';
import { CustomSelect } from '../../../../shared/components/Select';
import { WORKING_TIMES } from '../../../../shared/constants/workingTimes';
import {
    selectPlaceholder,
    setAllowClear,
    setClassName
} from '../../../../utils/componentPropHelpers';
import {
    getDescription,
    mapDataForDropdown
} from '../../../../utils/dropdownHelper';
import { checkTokenExp, redirectTokenExp } from '../../../../utils/tokenHelper';
import dropdownService from '../../../services/dropdownService';
import { formatDateByTimezone } from '../../../utils/dateHelpers';
import { validateForm } from '../../../utils/Details/MainInformation/mainInfoFormHelper';
import { shouldShowTakesEffectModal } from '../../../utils/Details/MainInformation/workingPeriodHelper';
import { statusElementOpen } from '../../../utils/employeeDetailsHelper';
import { normalizeRequestData } from '../../../utils/modelMapper';
import { DetailsInnerContainer } from '../../shared/DetailsInnerContainer';

const AdministrationLabel = 'Administration';
const AdministrationId = 10;

const INITIAL_VALUES = {
    workingPeriodId: null,
    startDate: null,
    endDate: null,
    departmentId: undefined,
    industryId: undefined,
    levelId: undefined,
    reasonForLeavingId: undefined,
    leaverTypeId: undefined,
    typeOfWorkId: undefined,
    hiringChannelId: undefined,
    hiringChannelText: null,
    mentormaterId: undefined,
    locationId: undefined,
    workLocation: null,
    voluntaryTypeId: undefined,
    workingTime: null,
    trialPeriod: null,
    reportsToId: undefined,
    positionId: undefined,
    onLeavePeriods: []
};

const WorkingPeriodBody = ({
    personId,
    initialValues = INITIAL_VALUES,
    isSectionOpen,
    workingPeriodIndex,
    previousWorkingPeriodEndDate = null,
    nextWorkingPeriodStartDate = null,
    setAllowAddWorkingPeriod,
    allowDelete,
    permissions
}) => {
    const [isOpen, setIsOpen] = useState(isSectionOpen);
    const [showInfoText, setShowInfoText] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [errors, setErrors] = useState({});
    const [callCounter, setCallCounter] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTakesEffectModalOpen, setIsTakesEffectModalOpen] = useState(false);
    const [valueToChange, setValueToChange] = useState({ name: '', value: '' });

    const dropdownsState = useSelector(state => state.dropdown);
    const { isLoadingStack, error } = useSelector(
        state => state.workingPeriods
    );
    const { person } = useSelector(state => state.people);
    const dispatch = useDispatch();
    const [initialValuesFormik, setInitialValuesFormik] = useState({
        ...INITIAL_VALUES,
        ...initialValues,
        reasonForLeavingId: initialValues.reasonForLeavingId ?? 9,
        leaverTypeId: initialValues.leaverTypeId ?? 3,
        voluntaryTypeId: initialValues.voluntaryTypeId ?? 3,
        positionTitle: getDescription(
            initialValues.positionId,
            dropdownsState.positions
        )
    });

    const typesOfMentormaterOptions = mapDataForDropdown(
        dropdownsState.typesOfMentormater
    );
    const locationsOptions = mapDataForDropdown(dropdownsState.locations);
    const reasonsForLeavingOptions = mapDataForDropdown(
        dropdownsState.reasonsForLeaving
    );
    const leaverTypesOptions = mapDataForDropdown(dropdownsState.leaverTypes);
    const voluntaryTypesOptions = mapDataForDropdown(
        dropdownsState.voluntaryTypes
    );
    const levelsOptions = mapDataForDropdown(dropdownsState.levels);
    const departmentsOptions = mapDataForDropdown(
        dropdownsState.departments.filter(x => x.active)
    );
    const industryOptions = mapDataForDropdown(dropdownsState.industries);
    const typesOfWorkOptions = mapDataForDropdown(dropdownsState.typesOfWork);
    const hiringChannelsOptions = mapDataForDropdown(
        dropdownsState.hiringChannels
    );
    const positionsOptions = mapDataForDropdown(dropdownsState.positions);

    const canEdit = !canEditWorkingPeriod(permissions);

    /** Delete model props */
    const personFirstName = person.name?.split(' ')[0];
    const handleDeleteWorkingPeriod = () => {
        const workingPeriodId = initialValues.workingPeriodId;
        const personId = initialValues.personId;
        setIsModalOpen(false);
        dispatch(
            removeWorkingPeriod(
                workingPeriodId,
                personId,
                dropdownsState.positions
            )
        );
    };

    const handleSectionActivity = () => {
        setIsOpen(!isOpen);
        if (isOpen) {
            dispatch(resetWorkingPeriodLoadingStack(workingPeriodIndex));
            setShowInfoText(false);
            setCallCounter(0);
        }
    };

    // Handle loading state
    useEffect(() => {
        if (!isEmpty(isLoadingStack[workingPeriodIndex])) {
            Object.values(isLoadingStack[workingPeriodIndex]).some(
                v => v === true
            )
                ? setIsSaved(false)
                : setIsSaved(true);
        }
    }, [isLoadingStack]);
    // Handle incoming errors
    useEffect(() => {
        if (error) {
            errorNotification(error, 5);
            dispatch(resetWorkingPeriodLoadingStack(workingPeriodIndex));
        }
    }, [error]);
    // Handle clear state
    useEffect(() => {
        return () => {
            dispatch(resetWorkingPeriodLoadingStack(workingPeriodIndex));
        };
    }, []);

    const handleSetAllowAddWorkingPeriod = val => {
        setAllowAddWorkingPeriod(prev => {
            let temp = [...prev];
            temp[workingPeriodIndex] = val;
            return temp;
        });
    };

    const validate = values => {
        const errorsIncome = validateForm(values);
        setErrors(errorsIncome);
    };

    const handleBlur = (inputName, value) => {
        const newValue = value?.toString().trim() ?? '';
        const initialVal = initialValuesFormik[inputName]?.toString() ?? '';
        if (shouldShowTakesEffectModal(initialValuesFormik, inputName)) {
            if (newValue !== initialVal && !errors[inputName]) {
                setIsTakesEffectModalOpen(true);
                setValueToChange({ name: inputName, value: value });
            }
        } else {
            if (newValue !== initialVal && !errors[inputName]) {
                saveUpdate(inputName, newValue, undefined);
            }
        }
    };

    const handleModalSubmit = takesEffectOn => {
        saveUpdate(valueToChange.name, valueToChange.value, takesEffectOn);
        setIsTakesEffectModalOpen(false);
    };

    const saveUpdate = (inputName, value, takesEffectOn) => {
        let endDateChange = '';
        setShowInfoText(true);

        //handle show add new working period button
        if (inputName === 'endDate') {
            !isEmpty(value)
                ? handleSetAllowAddWorkingPeriod(true)
                : handleSetAllowAddWorkingPeriod(false);
        }

        if (inputName === 'endDate') {
            if (isEmpty(initialValuesFormik['endDate']) && !isEmpty(value)) {
                endDateChange = 'create';
            } else if (
                !isEmpty(initialValuesFormik['endDate']) &&
                isEmpty(value)
            ) {
                endDateChange = 'delete';
            }
        }

        // Handle form state
        setInitialValuesFormik(prev => {
            let temp = { ...prev };
            if (inputName === 'endDate') {
                if (temp[inputName] === null || value === null) {
                    temp.reasonForLeavingId = 9;
                    temp.leaverTypeId = 3;
                    temp.voluntaryTypeId = 3;
                } else if (temp[inputName] !== null || value !== null) {
                    temp.reasonForLeavingId = null;
                    temp.leaverTypeId = null;
                    temp.voluntaryTypeId = null;
                }
            }

            temp[inputName] = value;
            return temp;
        });
        setCallCounter(prev => prev + 1);

        if (endDateChange === 'create') {
            // Handles end date change of data(when creating it)
            const requestObj = {
                endDate: value,
                leaverTypeId: 3,
                reasonForLeavingId: 9,
                voluntaryTypeId: 3
            };

            dispatch(
                createEndDateWorkingPeriod(
                    workingPeriodIndex,
                    callCounter + 1,
                    initialValues.workingPeriodId,
                    normalizeRequestData(requestObj),
                    personId
                )
            );
        } else if (endDateChange === 'delete') {
            // Handles end date change of data(when deleting it)
            dispatch(
                deleteEndDateWorkingPeriod(
                    workingPeriodIndex,
                    callCounter + 1,
                    initialValues.workingPeriodId
                )
            );
        } else {
            // Handles normal change of data
            const requestObj = {
                inputName,
                value: value?.toString(),
                takesEffectOn
            };

            dispatch(
                updateWorkingPeriod(
                    workingPeriodIndex,
                    callCounter + 1,
                    initialValues.workingPeriodId,
                    normalizeRequestData(requestObj)
                )
            );
        }
    };

    const onTakesEffectModalCancel = () => {
        dispatch(fetchWorkingPeriods(initialValues.personId));
    };

    //set title value
    const bodyTitle = 'Working Period';

    //set status value
    const statusOpen = showInfoText && statusElementOpen(isSaved);
    const statusElementClose = (
        <div className="status-state">
            {`Starting date - ${formatDateByTimezone(
                initialValuesFormik.startDate
            )} | Ending date - ${formatDateByTimezone(
                initialValuesFormik.endDate
            )}`}
        </div>
    );
    const statusClose = statusElementClose;
    const statusText = isOpen ? statusOpen : statusClose;

    const fetchPersonData = async (filter, previousId = null) => {
        try {
            if (checkTokenExp()) {
                redirectTokenExp();
            }
            const resp = await dropdownService.getReportsTo(filter, previousId);
            const { data } = resp;
            return data;
        } catch (e) {
            const msg = e.message;
            errorNotification(msg);
        }
    };

    const specialHandleChange = onChange => {
        onChange('reasonForLeavingId', 9);
        onChange('leaverTypeId', 3);
        onChange('voluntaryTypeId', 3);
    };

    return (
        <DetailsInnerContainer
            bodyTitle={bodyTitle}
            statusText={statusText}
            isOpen={isOpen}
            handleSection={handleSectionActivity}
        >
            <StyledWorkingPeriodBody>
                <Formik initialValues={initialValuesFormik} validate={validate}>
                    {props => {
                        const { values, setFieldValue } = props;

                        return (
                            <Form>
                                <div className="section-splitter"></div>
                                <FieldsSection>
                                    <div className="section-inputs">
                                        <CustomDateSelector
                                            value={values.startDate}
                                            label="First Date *"
                                            name="startDate"
                                            allowClear={false}
                                            onChange={setFieldValue}
                                            onBlur={(name, val) => {
                                                handleBlur(name, val);
                                            }}
                                            disabledDateIncluding
                                            disabledDate={
                                                previousWorkingPeriodEndDate
                                            }
                                            disabled={canEdit}
                                        />
                                        <CustomDateSelector
                                            value={values.endDate}
                                            label="End Date"
                                            name="endDate"
                                            specialHandleChange={
                                                specialHandleChange
                                            }
                                            disabledDate={values.startDate}
                                            disabledFutureDate={
                                                nextWorkingPeriodStartDate
                                            }
                                            disabledDateIncluding
                                            onChange={setFieldValue}
                                            onBlur={(name, val) => {
                                                handleBlur(name, val);
                                            }}
                                            disabled={canEdit}
                                        />
                                        <CustomSelect
                                            value={values.mentormaterId}
                                            label="Type of MentorMater"
                                            name="mentormaterId"
                                            placeholder={selectPlaceholder}
                                            allowClear={setAllowClear(
                                                values.mentormaterId
                                            )}
                                            options={typesOfMentormaterOptions}
                                            onChange={setFieldValue}
                                            onBlur={(name, val) => {
                                                handleBlur(name, val);
                                            }}
                                            disabled={canEdit}
                                        />
                                        <CustomInput
                                            className={setClassName(
                                                errors?.trialPeriod
                                            )}
                                            value={values.trialPeriod}
                                            label="Trial Period"
                                            name="trialPeriod"
                                            placeholder="Type..."
                                            allowClear={true}
                                            specialEnter={true}
                                            isNormalChange={false}
                                            onChange={(name, val) => {
                                                setFieldValue(name, val);
                                            }}
                                            onBlur={name => {
                                                handleBlur(
                                                    name,
                                                    values.trialPeriod
                                                );
                                            }}
                                            disabled={canEdit}
                                        />
                                        <CustomSelect
                                            value={values.workingTime}
                                            label="Working Time *"
                                            name="workingTime"
                                            placeholder="Working Time"
                                            options={WORKING_TIMES}
                                            onChange={setFieldValue}
                                            onBlur={(name, val) => {
                                                handleBlur(name, val);
                                            }}
                                            disabled={canEdit}
                                        />
                                        <CustomSelect
                                            value={values.locationId}
                                            label="Dedicated Office"
                                            name="locationId"
                                            placeholder={selectPlaceholder}
                                            allowClear={setAllowClear(
                                                values.locationId
                                            )}
                                            options={locationsOptions}
                                            onChange={setFieldValue}
                                            onBlur={(name, val) => {
                                                handleBlur(name, val);
                                            }}
                                            disabled={canEdit}
                                        />
                                        <CustomInput
                                            className={setClassName(
                                                errors?.workLocation
                                            )}
                                            value={values.workLocation}
                                            label="Work Location"
                                            name="workLocation"
                                            placeholder="Type..."
                                            allowClear={true}
                                            specialEnter={true}
                                            isNormalChange={false}
                                            onChange={(name, val) => {
                                                setFieldValue(name, val);
                                            }}
                                            onBlur={name => {
                                                handleBlur(
                                                    name,
                                                    values.workLocation
                                                );
                                            }}
                                            disabled={canEdit}
                                        />
                                        <AsyncSearchSelect
                                            label="Reports to"
                                            value={values.reportsToId}
                                            name={'reportsToId'}
                                            displayDefaultOption={false}
                                            setValueOption={setFieldValue}
                                            onBlur={(name, val) => {
                                                handleBlur(name, val);
                                            }}
                                            placeholder={selectPlaceholder}
                                            getData={fetchPersonData}
                                            allowClear={true}
                                            initialCallProps={[
                                                '',
                                                [values.reportsToId]
                                            ]}
                                            disabled={canEdit}
                                        />
                                    </div>
                                </FieldsSection>
                                {values.endDate && (
                                    <FieldsSection>
                                        <div className="section-inputs">
                                            <CustomSelect
                                                value={
                                                    values.reasonForLeavingId
                                                }
                                                label="Main reason for leaving"
                                                name="reasonForLeavingId"
                                                allowClear={false}
                                                options={
                                                    reasonsForLeavingOptions
                                                }
                                                onChange={setFieldValue}
                                                onBlur={(name, val) => {
                                                    handleBlur(name, val);
                                                }}
                                                disabled={canEdit}
                                            />
                                            <CustomSelect
                                                value={values.leaverTypeId}
                                                label="Leaver Types"
                                                name="leaverTypeId"
                                                allowClear={false}
                                                options={leaverTypesOptions}
                                                onChange={setFieldValue}
                                                onBlur={(name, val) => {
                                                    handleBlur(name, val);
                                                }}
                                                disabled={canEdit}
                                            />
                                            <CustomSelect
                                                value={values.voluntaryTypeId}
                                                label="Voluntary"
                                                name="voluntaryTypeId"
                                                allowClear={false}
                                                options={voluntaryTypesOptions}
                                                onChange={setFieldValue}
                                                onBlur={(name, val) => {
                                                    handleBlur(name, val);
                                                }}
                                                disabled={canEdit}
                                            />
                                        </div>
                                    </FieldsSection>
                                )}
                                <div className="section-splitter"></div>
                                <FieldsSection>
                                    <div className="section-inputs">
                                        <CustomSelect
                                            value={values.levelId}
                                            label="Level"
                                            name="levelId"
                                            placeholder={selectPlaceholder}
                                            allowClear={setAllowClear(
                                                values.levelId
                                            )}
                                            options={levelsOptions}
                                            onChange={setFieldValue}
                                            onBlur={(name, val) => {
                                                handleBlur(name, val);
                                            }}
                                            disabled={canEdit}
                                        />
                                        <CustomSelect
                                            value={
                                                values.departmentId ==
                                                AdministrationId
                                                    ? AdministrationLabel
                                                    : values.departmentId
                                            }
                                            label="Department"
                                            name="departmentId"
                                            placeholder={selectPlaceholder}
                                            allowClear={setAllowClear(
                                                values.departmentId
                                            )}
                                            options={departmentsOptions.filter(
                                                department =>
                                                    department.label !=
                                                    AdministrationLabel
                                            )}
                                            onChange={setFieldValue}
                                            onBlur={(name, val) => {
                                                handleBlur(name, val);
                                            }}
                                            disabled={canEdit}
                                        />
                                        <CustomSelect
                                            value={values.industryId}
                                            label="Industry"
                                            name="industryId"
                                            placeholder={selectPlaceholder}
                                            allowClear={setAllowClear(
                                                values.industryId
                                            )}
                                            options={industryOptions}
                                            onChange={setFieldValue}
                                            onBlur={(name, val) => {
                                                handleBlur(name, val);
                                            }}
                                            disabled={canEdit}
                                        />
                                        <CustomSelect
                                            value={values.typeOfWorkId}
                                            label="Type of Work"
                                            name="typeOfWorkId"
                                            placeholder={selectPlaceholder}
                                            allowClear={setAllowClear(
                                                values.typeOfWorkId
                                            )}
                                            options={typesOfWorkOptions}
                                            onChange={setFieldValue}
                                            onBlur={(name, val) => {
                                                handleBlur(name, val);
                                            }}
                                            disabled={canEdit}
                                        />
                                        <CustomSelect
                                            label="Position"
                                            allowClear={true}
                                            placeholder="Type to autocomplete..."
                                            options={positionsOptions}
                                            value={
                                                values.positionTitle ??
                                                undefined
                                            }
                                            name={'positionTitle'}
                                            onChange={(name, val) => {
                                                const selectedPosition =
                                                    positionsOptions.find(
                                                        p => p.value === val
                                                    );
                                                setFieldValue(
                                                    name,
                                                    selectedPosition?.label
                                                );
                                            }}
                                            onBlur={(name, val) => {
                                                const selectedPosition =
                                                    positionsOptions.find(
                                                        p => p.value === val
                                                    );
                                                handleBlur(
                                                    name,
                                                    selectedPosition.label
                                                );
                                            }}
                                            className={setClassName(
                                                errors?.positionTitle
                                            )}
                                            disabled={canEdit}
                                            hasInput={true}
                                        />
                                    </div>
                                </FieldsSection>
                                <div className="section-splitter"></div>
                                <FieldsSection>
                                    <div className="section-inputs">
                                        <CustomSelect
                                            value={values.hiringChannelId}
                                            label="Hiring Channel"
                                            name="hiringChannelId"
                                            placeholder={selectPlaceholder}
                                            allowClear={setAllowClear(
                                                values.hiringChannelId
                                            )}
                                            options={hiringChannelsOptions}
                                            onChange={setFieldValue}
                                            onBlur={(name, val) => {
                                                handleBlur(name, val);
                                            }}
                                            disabled={canEdit}
                                        />
                                        <CustomInput
                                            className={setClassName(
                                                errors?.hiringChannelText
                                            )}
                                            value={values.hiringChannelText}
                                            allowClear={true}
                                            name="hiringChannelText"
                                            placeholder="Type..."
                                            noLabelStyle={true}
                                            specialEnter={true}
                                            isNormalChange={false}
                                            onChange={(name, val) => {
                                                setFieldValue(name, val);
                                            }}
                                            onBlur={name => {
                                                handleBlur(
                                                    name,
                                                    values.hiringChannelText
                                                );
                                            }}
                                            disabled={canEdit}
                                        />
                                    </div>
                                </FieldsSection>
                                <div className="section-splitter" />
                                <OnLeaveFormSection
                                    canEdit={canEdit}
                                    workingPeriodIndex={workingPeriodIndex}
                                    personId={personId}
                                    setShowInfoText={setShowInfoText}
                                />
                            </Form>
                        );
                    }}
                </Formik>
                {allowDelete && (
                    <>
                        <div className="section-splitter"></div>
                        <div
                            className={`delete-section ${getDisabledClassNameDeleteWorkingPeriod(
                                permissions
                            )}`}
                            onClick={() =>
                                canDeleteWorkingPeriod(permissions)
                                    ? setIsModalOpen(true)
                                    : undefined
                            }
                        >
                            Delete working period
                        </div>
                    </>
                )}
                {isModalOpen && (
                    <CustomConfirmationModal
                        visible={isModalOpen}
                        setVisible={setIsModalOpen}
                        title={'Confirmation'}
                        contentText={`You are going to delete ${personFirstName}’s record.`}
                        handleConfirm={handleDeleteWorkingPeriod}
                    />
                )}
                {isTakesEffectModalOpen && (
                    <TakesEffectModal
                        visible={isTakesEffectModalOpen}
                        setVisible={setIsTakesEffectModalOpen}
                        title={'Set Date'}
                        valueForChangeName={valueToChange.name}
                        handleSubmit={handleModalSubmit}
                        onCancel={onTakesEffectModalCancel}
                        startWorkingPeriodDate={initialValuesFormik.startDate}
                        endWorkingPeriodDate={initialValuesFormik.endDate}
                    />
                )}
            </StyledWorkingPeriodBody>
        </DetailsInnerContainer>
    );
};
export { WorkingPeriodBody };
