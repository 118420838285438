import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

httpService.axios.interceptors.request.use(request => {
    if (getJwt()) {
        request.headers['Authorization'] = `Bearer ${getJwt()}`;
    }
    return request;
});

const login = async googleToken => {
    const data = await httpService.post(url.CONFIRM_USER_LOGIN(), {
        tokenId: googleToken
    });

    return data.data;
};

const loginWithMicrosoft = async microsoftToken => {
    const data = await httpService.post(url.CONFIRM_USER_LOGIN_MICROSOFT(), {
        tokenId: microsoftToken
    });

    return data.data;
};

const logout = () => {
    localStorage.clear();
    localStorage.setItem('logout', 'logout');
    sessionStorage.clear();
};

const getJwt = () => {
    if (localStorage.getItem('token')) return localStorage.getItem('token');
    return null;
};

const getUserData = () => {
    return JSON.parse(localStorage.getItem('profile'));
};

export default { login, loginWithMicrosoft, logout, getJwt, getUserData };
