import { createSlice } from '@reduxjs/toolkit';
import discountService from 'admin/services/discountService';
import { errorNotification } from 'shared/components';

const initialState = {
    take: 10,
    sortBy: 'UpdatedOn',
    sortAsc: false,
    searchPhrase: '',

    discounts: [],
    currentTotal: 0,
    discountsTotalCount: 0,
    locations: [],

    isLoading: false,
    error: null,
    isEditMode: false,
    isAddedSuccessfully: undefined,
    isEditedSuccessfully: undefined,
    isDeletedSuccessfully: undefined,
    isLoadingFetchSingle: false,
    loadActive: true,

    singleDiscountData: {
        discountId: undefined,
        discountMinimum: undefined,
        discountMaximum: undefined,
        discountName: '',
        discountVisible: false,
        discountImage: '',
        discountImageAltText: '',
        discountShortDescription: '',
        discountWebsite: '',
        discountHowMuch: { plainText: '', html: '' },
        discountHowToUse: { plainText: '', html: '' },
        discountCategory: undefined,
        discounIsActive: false,
        discountFiles: [],
        discountFilesSetForDeletion: [],
        discountLocations: []
    }
};

const { reducer: discountReducer, actions } = createSlice({
    name: 'discount',
    initialState,
    reducers: {
        // Fetch discounts
        changeLoadActive: (state, action) => {
            state.loadActive = action.payload;
        },
        updateSearchPhrase: (state, action) => {
            state.searchPhrase = action.payload;
        },
        resetNotifications: state => {
            state.isAddedSuccessfully = undefined;
            state.isEditedSuccessfully = undefined;
            state.isDeletedSuccessfully = undefined;
        },
        fetchDiscountsStart: state => {
            state.isLoading = true;
        },
        fetchDiscountsSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.error = null;
            state.discounts = [...state.discounts, ...data];
            state.currentTotal = state.currentTotal + data.length;
            state.discountsTotalCount = totalCount;
        },
        fetchDiscountsFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        changeOrderState: (state, action) => {
            const {
                payload: { sortBy, sortAsc }
            } = action;
            state.sortBy = sortBy;
            state.sortAsc = sortAsc;
        },

        updateDiscountSuccess: state => {
            state.isLoadingEdit = false;
            state.isEditedSuccessfully = true;
        },
        updateDiscountFailure: state => {
            state.isLoadingEdit = false;
            state.isLoading = false;
        },

        // clear state
        cleanState: state => {
            state.take = 10;
            state.discounts = [];
            state.currentTotal = 0;
            state.discountsTotalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        resetFilters: state => {
            state.take = 10;
            state.sortBy = 'UpdatedOn';
            state.sortAsc = false;
            state.searchPhrase = '';
            state.loadActive = true;
            state.discounts = [];
            state.currentTotal = 0;
            state.discountsTotalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        setLoadStateToActive: state => {
            state.active = true;
        },

        updateDiscountStart: state => {
            state.isLoading = true;
            state.editError = null;
            state.isLoadingEdit = true;
        },

        resetShowUpdate: state => {
            state.showUpdateMessage = false;
        },
        createDiscountStart: state => {
            state.isLoading = true;
            state.isLoadingCreate = true;
        },
        createDiscountFailure: (state, action) => {
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.error = action.payload;
        },
        createDiscountSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.showCreateMessage = true;
            state.error = null;

            state.discounts = [...data];
            state.currentTotal = data.length;
            state.discountsTotalCount = totalCount;
            state.inactiveDiscounts = [];
            state.inactiveTotal = 0;
            state.inactiveDiscountsTotalCount = 0;
            state.isAddedSuccessfully = true;
        },
        deleteDiscountStart: state => {
            state.isLoadingDelete = true;
            state.deleteError = null;
            state.isLoading = true;
        },
        deleteDiscountSuccess: state => {
            state.isLoadingDelete = false;
            state.isLoading = false;
            state.deleteError = null;
            state.isDeletedSuccessfully = true;
            state.error = null;
        },
        deleteDiscountFailure: (state, action) => {
            state.isLoadingDelete = false;
            state.isLoading = false;
            state.deleteError = action.payload;
            state.isDeletedSuccessfully = false;
        },
        resetDiscountModal: state => {
            state.singleDiscountData = initialState.singleDiscountData;
            state.isEditMode = false;
        },

        fetchSingleDiscountStart: state => {
            state.isLoadingFetchSingle = true;
            state.isEditMode = true;
        },

        fetchSingleDiscountSuccess: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.error = null;

            action.payload.discountHowMuch = {
                plainText: '',
                html: action.payload.discountHowMuch
            };
            action.payload.discountHowToUse = {
                plainText: '',
                html: action.payload.discountHowToUse
            };

            action.payload.discountCategory = {
                label: action.payload.discountCategoryName,
                value: action.payload.discountCategoryId
            };

            state.singleDiscountData = action.payload;
        },

        fetchSingleDiscountFailure: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.error = action.payload;
        }
    }
});

const fetchDiscounts = isScrolled => {
    return async (dispatch, getState) => {
        if (getState().discount.isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchDiscountsStart());
            let skip = getState().discount.currentTotal;
            let take = getState().discount.take;

            if (!isScrolled) {
                skip = 0;
                take = 10;
                dispatch(actions.cleanState());
            }

            const { sortAsc, sortBy, loadActive, searchPhrase } =
                getState().discount;
            const resp = await discountService.getAllDiscounts({
                skip,
                take,
                sortBy,
                sortAsc,
                searchPhrase,
                loadActive
            });
            const totalCount = resp.metadata.totalCount;
            const data = resp.data;
            dispatch(actions.fetchDiscountsSuccess({ data, totalCount }));
        } catch (err) {
            dispatch(actions.fetchDiscountsFailure(err));
        }
    };
};

const updateDiscountVisibility = discountId => {
    return async (dispatch, getState) => {
        if (getState().discount.isLoadingEdit) {
            return;
        }

        try {
            dispatch(actions.updateDiscountStart());
            await discountService.updateDiscountVisibility(discountId);
            const skip = 0;
            const take = getState().discount.take;
            await discountService.getAllDiscounts(
                { skip, take },
                getState().discount.loadActive
            );

            dispatch(actions.updateDiscountSuccess());
            await reloadDiscounts(dispatch, getState);
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.updateDiscountFailure(errorMessage));
        }
    };
};

const changeOrder = orderValue => {
    const { id, desc } = orderValue[0];
    const sortBy = id.charAt(0).toUpperCase() + id.slice(1);
    const sortAsc = sortBy === 'UpdatedOn' && desc === false ? true : !desc;
    return async dispatch => {
        dispatch(actions.changeOrderState({ sortBy, sortAsc }));
    };
};

const resetDiscountState = () => {
    return dispatch => {
        dispatch(actions.cleanState());
    };
};

const resetFilters = () => {
    return dispatch => {
        dispatch(actions.resetFilters());
    };
};

const resetDiscount = () => {
    return dispatch => {
        dispatch(actions.resetDiscountModal());
    };
};

const resetShowUpdateMessage = () => {
    return dispatch => {
        dispatch(actions.resetShowUpdate());
    };
};

const fetchSingleDiscount = discountId => {
    return async (dispatch, getState) => {
        if (getState().role.isLoadingFetchSingle) {
            return;
        }

        try {
            dispatch(actions.fetchSingleDiscountStart());
            const data = await discountService.getDiscountById(discountId);
            dispatch(actions.fetchSingleDiscountSuccess(data.data));
        } catch (err) {
            dispatch(
                actions.fetchSingleDiscountFailure('Something went wrong.')
            );
        }
    };
};

const createDiscount = requestData => {
    return async (dispatch, getState) => {
        if (getState().discount.isLoadingCreate) {
            return;
        }

        try {
            dispatch(actions.createDiscountStart());
            await discountService.addDiscount(requestData);
            const skip = 0;
            const { take, loadActive } = getState().discount;
            const resp = await discountService.getAllDiscounts({
                skip,
                take,
                loadActive
            });
            const {
                data,
                metadata: { totalCount }
            } = resp;
            dispatch(actions.createDiscountSuccess({ data, totalCount }));
        } catch (error) {
            const errorMessage =
                error.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.createDiscountFailure(errorMessage));
        }
    };
};

const deleteDiscount = discountId => {
    return async (dispatch, getState) => {
        if (getState().discount.isLoadingDelete) {
            return;
        }
        dispatch(actions.deleteDiscountStart());

        try {
            const response = await discountService.deleteDiscount(discountId);

            if (response) {
                await reloadDiscounts(dispatch, getState);

                dispatch(actions.deleteDiscountSuccess());
            } else {
                dispatch(actions.deleteDiscountFailure('Something went wrong'));
            }
        } catch (err) {
            dispatch(actions.deleteDiscountFailure('Something went wrong'));
        }
    };
};

const reloadDiscounts = async (dispatch, getState) => {
    const { sortAsc, sortBy, skip, take, searchPhrase, loadActive } =
        getState().discount;
    const resp = await discountService.getAllDiscounts({
        skip,
        take,
        sortBy,
        sortAsc,
        searchPhrase,
        loadActive
    });
    const {
        data,
        metadata: { totalCount }
    } = resp;
    dispatch(actions.fetchDiscountsSuccess({ data, totalCount }));
};

const updateDiscount = (discountId, discountData) => {
    return async (dispatch, getState) => {
        try {
            dispatch(actions.updateDiscountStart());
            await discountService.updateDiscount(discountId, discountData);
            await reloadDiscounts(dispatch, getState);
            dispatch(actions.updateDiscountSuccess());
            dispatch(actions.resetDiscountModal());
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.updateDiscountFailure(errorMessage));
        }
    };
};

const changeActiveState = loadActive => {
    return async dispatch => dispatch(actions.changeLoadActive(loadActive));
};

const resetNotificationState = () => {
    return async dispatch => dispatch(actions.resetNotifications());
};

const updateSearchPhrase = searchPhrase => {
    return async dispatch => dispatch(actions.updateSearchPhrase(searchPhrase));
};

export {
    discountReducer,
    actions as discountActions,
    updateSearchPhrase,
    fetchDiscounts,
    changeOrder,
    resetDiscountState,
    resetFilters,
    updateDiscountVisibility,
    resetDiscount,
    resetShowUpdateMessage,
    fetchSingleDiscount,
    createDiscount,
    deleteDiscount,
    updateDiscount,
    changeActiveState,
    resetNotificationState
};
