import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import relativeMemberService from '../../people/services/relativeMemberService';

const initialState = {
    relativeMembers: [],
    error: null,
    isLoading: false,
    isLoadingStack: {}
};

const { reducer: relativeMemberReducer, actions } = createSlice({
    name: 'relativeMembers',
    initialState,
    reducers: {
        //Fetch relative members
        fetchRelativeMembersStart: state => {
            state.isLoading = true;
        },
        fetchRelativeMembersSuccess: (state, action) => {
            state.isLoading = false;
            state.relativeMembers = action.payload;
            state.error = null;
        },
        fetchRelativeMembersFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        //Create relative member
        createRelativeMemberStart: state => {
            state.isLoading = true;
        },
        createRelativeMemberSuccess: (state, action) => {
            state.isLoading = false;
            state.relativeMembers = [...state.relativeMembers, action.payload];
            state.error = null;
        },
        createRelativeMemberFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        //Update relative member
        updateRelativeMemberStart: (state, action) => {
            const {
                payload: { relativeMemberIndex, callIndex }
            } = action;
            state.isLoadingStack[relativeMemberIndex] = {
                ...state.isLoadingStack[relativeMemberIndex],
                [callIndex]: true
            };
        },
        updateRelativeMemberSuccess: (state, action) => {
            const {
                payload: { relativeMemberIndex, callIndex, requestData }
            } = action;
            state.relativeMembers[relativeMemberIndex] = { ...requestData };
            state.isLoadingStack[relativeMemberIndex] = {
                ...state.isLoadingStack[relativeMemberIndex],
                [callIndex]: undefined
            };
            state.error = null;
        },
        updateRelativeMemberFailure: (state, action) => {
            const {
                payload: { relativeMemberIndex, callIndex, errorMessage }
            } = action;
            state.isLoadingStack[relativeMemberIndex] = {
                ...state.isLoadingStack[relativeMemberIndex],
                [callIndex]: undefined
            };
            state.error = errorMessage;
        },

        //Delete relative member
        deleteRelativeMemberStart: state => {
            state.isLoading = true;
        },
        deleteRelativeMemberSuccess: (state, action) => {
            state.isLoading = false;
            state.relativeMembers = state.relativeMembers.filter(
                x => x.personRelativeMemberId !== action.payload
            );
            state.error = null;
        },
        deleteRelativeMemberFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        //Clear state
        clearState: state => {
            state.relativeMembers = [];
            state.isLoading = false;
            state.error = null;
            state.isLoadingStack = {};
        },

        //Reset relative member loading state
        resetLoadingStack: state => {
            state.isLoadingStack = {};
            state.error = null;
        }
    }
});

const fetchRelativeMembers = personId => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchRelativeMembersStart());
            const { data } = await relativeMemberService.getRelativeMembers(
                personId
            );
            dispatch(actions.fetchRelativeMembersSuccess(data));
        } catch (err) {
            dispatch(
                actions.fetchRelativeMembersFailure('Something went wrong')
            );
        }
    };
};

const addRelativeMember = personId => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }
        try {
            dispatch(actions.createRelativeMemberStart());
            const { data } = await relativeMemberService.postRelativeMember(
                personId
            );
            dispatch(actions.createRelativeMemberSuccess(data));
        } catch (err) {
            dispatch(
                actions.createRelativeMemberFailure('Something went wrong')
            );
        }
    };
};

const updateRelativeMember = (
    relativeMemberIndex,
    callIndex,
    relativeMemberId,
    requestData,
    permissions
) => {
    return async dispatch => {
        try {
            dispatch(
                actions.updateRelativeMemberStart({
                    relativeMemberIndex,
                    callIndex
                })
            );
            requestData = {
                ...requestData,
                personalId: permissions.canViewRelativePersonalId
                    ? requestData.personalId
                    : null,
                birthday:
                    requestData.birthday !== null
                        ? moment(requestData.birthday).format('YYYY-MM-DD')
                        : null
            };
            await relativeMemberService.patchRelativeMemberById(
                relativeMemberId,
                requestData
            );
            dispatch(
                actions.updateRelativeMemberSuccess({
                    relativeMemberIndex,
                    callIndex,
                    requestData
                })
            );
        } catch (err) {
            dispatch(
                actions.updateRelativeMemberFailure({
                    relativeMemberIndex,
                    callIndex,
                    errorMessage: 'Something went wrong'
                })
            );
        }
    };
};

const removeRelativeMember = relativeMemberId => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }
        try {
            dispatch(actions.deleteRelativeMemberStart());
            await relativeMemberService.deleteRelativeMemberById(
                relativeMemberId
            );
            dispatch(actions.deleteRelativeMemberSuccess(relativeMemberId));
        } catch (err) {
            dispatch(
                actions.deleteRelativeMemberFailure('Something went wrong')
            );
        }
    };
};

const resetRelativeMemberState = () => {
    return dispatch => {
        dispatch(actions.clearState());
    };
};

const resetRelativeMemberLoadingStack = () => {
    return dispatch => {
        dispatch(actions.resetLoadingStack());
    };
};

export {
    relativeMemberReducer,
    actions as relativeMemberActions,
    fetchRelativeMembers,
    addRelativeMember,
    updateRelativeMember,
    removeRelativeMember,
    resetRelativeMemberState,
    resetRelativeMemberLoadingStack
};
