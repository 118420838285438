import styled from 'styled-components';

import { CustomButton } from '../../../shared/components/Button';

const StyledLoginButton = styled(CustomButton)`
    width: 100%;
    .button {
        background: var(--mainBlue);
        margin-bottom: 0px;
        width: 100%;
        height: 43px;
        color: var(--primaryWhite);
        .icon {
            margin-right: 10px;
            svg {
                width: 17px;
                height: 17px;
            }
        }
    }
    .button:hover {
        color: var(--lesserPrimaryWhite);
    }
    .btn-disabled,
    .btn-disabled:hover {
        color: var(--primaryWhite) !important;
    }
`;
StyledLoginButton.displayName = 'login-button';

export { StyledLoginButton };
